@import 'mixins.less';
@import 'config.less';

img {
  .wMax(100%);
}
._z {
  .zero;
}
._tac {
  .tac;
}
._tar {
  .tRight;
}
.flx {
  .dFlex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &.-sa {
    justify-content: space-around;
    -ms-flex-pack: justify;
  }
  &.-sb {
    justify-content: space-between;
    -ms-flex-pack: justify;
  }
  &.-c {
    justify-content: center;
  }
  &.-e {
    justify-content: flex-end;
  }

  &.-alc {
    align-items: center;
  }


}
.iflx {
  .dFlexi;
  &.-sa {
    justify-content: space-around;
  }
  &.-sb {
    justify-content: space-between;
  }
}
.row {
  .dFlex;
  .flexWrap;
  .flexSb();
  .mb(1rem);

	//.col-1-3 {
	//	.w(6.3%);
	//}

  &.-fs {
    justify-content: flex-start;
    > *:not(:last-child) {
      .mr(1%);
    }
  }
}
.col-2 {
  .w(49.5%);
}
.col-3 {
  .w(32.5%);
}
.col-4 {
  .w(24.5%);
}
.col-2-3 {
  .w(66.3%);
}
//отступы сверху
.mt5 {
  .mTop(5px);
}
.mt10 {
  .mTop(10px);
}
.mt15 {
  .mTop(15px);
}
.mt20 {
  .mTop(20px);
}
.mt20m {
  .mTop(-20px);
}
.mt25 {
  .mTop(25px);
}
.mt30 {
  .mTop(30px);
}
.mt40 {
  .mTop(40px);
}
.pt20 {
  .pt(20px);
}
.w100 {
  .w100;
}
//.aw60 {
//  .w(60vw);
//  @media (max-width: 1000px) {
//    .w100;
//  }
//}
//._pz {
//  p {
//    .m(0);
//  }
//}
//img.imx, .imx img {
//  .wMax(100%);
//}

//.ic {
//  .relative;
//  img {
//    .imgCentered;
//  }
//
//}
.clr-red {
  .clr(red) !important;
}
.edited-info {
  .fs(.8rem);
  .clr(@greyBgrHover);
}
.bold {
  .bold;
}
.red-box {
  border: 1px solid red;
  padding: 10px;
  color: red;
  .tac;
  .bold;
}
.noselect {
  -webkit-touch-callout: none; // iOS Safari */
  -webkit-user-select: none; // Safari */
  -khtml-user-select: none; // Konqueror HTML */
  -moz-user-select: none; // Old versions of Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none;
}