img {
  max-width: 100%;
}
._z {
  margin: 0;
  padding: 0;
  list-style: none;
}
._tac {
  text-align: center;
}
._tar {
  text-align: right;
}
.flx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.flx.-sa {
  justify-content: space-around;
  -ms-flex-pack: justify;
}
.flx.-sb {
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.flx.-c {
  justify-content: center;
}
.flx.-e {
  justify-content: flex-end;
}
.flx.-alc {
  align-items: center;
}
.iflx {
  display: inline-flex;
}
.iflx.-sa {
  justify-content: space-around;
}
.iflx.-sb {
  justify-content: space-between;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -ms-flex-pack: justify;
  margin-bottom: 1rem;
}
.row.-fs {
  justify-content: flex-start;
}
.row.-fs > *:not(:last-child) {
  margin-right: 1%;
}
.col-2 {
  width: 49.5%;
}
.col-3 {
  width: 32.5%;
}
.col-4 {
  width: 24.5%;
}
.col-2-3 {
  width: 66.3%;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt20m {
  margin-top: -20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.pt20 {
  padding-top: 20px;
}
.w100 {
  width: 100%;
}
.clr-red {
  color: red !important;
}
.edited-info {
  font-size: 0.8rem;
  color: #494e53;
}
.bold {
  font-weight: bold;
}
.red-box {
  border: 1px solid red;
  padding: 10px;
  color: red;
  text-align: center;
  font-weight: bold;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type=text],
input[type=tel],
input[type=password],
input[type=email],
input[type=date],
textarea,
select,
.input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: inherit;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type=text]:focus,
input[type=tel]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=date]:focus,
textarea:focus,
select:focus,
.input:focus {
  border-color: #80bdff;
  outline: 0;
}
@media print {
  input[type=text],
  input[type=tel],
  input[type=password],
  input[type=email],
  input[type=date],
  textarea,
  select,
  .input {
    font-size: 8pt;
  }
}
.form-item {
  margin-bottom: 20px;
}
.form-item > label {
  display: block;
  color: #000;
}
.form-item > label:not(.check) {
  margin-bottom: 5px;
  font-weight: 700;
}
.form-item > label span {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 300;
  color: #758392;
  text-transform: lowercase;
}
.form-item.-lbl-normal > label {
  font-weight: 500;
}
.form-item .descr {
  font-size: 0.8rem;
  font-weight: 300;
  font-style: italic;
  color: #758392;
}
.form-item .descr .predefined {
  margin-top: 5px;
}
.form-item .descr .predefined > * {
  display: inline-block;
  background: #ced4da;
  margin: 0 5px 5px 0;
  padding: 2px 5px;
  cursor: pointer;
  font-style: normal;
}
.form-item .descr .predefined > *:hover {
  background: #343A40;
  color: #fff;
}
.form-item.disabled p {
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background: #f1f1f1;
}
.form-item.disabled p em {
  color: #bebebe;
}
.form-item.disabled input,
.form-item.disabled textarea,
.form-item.disabled p,
.form-item.disabled select {
  border: #f1f1f1 1px solid;
}
.form-item.disabled input:focus,
.form-item.disabled textarea:focus,
.form-item.disabled p:focus,
.form-item.disabled select:focus {
  border-color: #f1f1f1;
}
.form-item.disabled .fi-field .-ico,
.form-item.disabled .fi-field .-icor {
  border: #f1f1f1 1px solid;
}
.form-item .sun-editor-editable {
  font-family: 'Roboto', sans-serif;
}
.fi-field {
  position: relative;
  display: block;
  width: 100%;
  min-height: calc(2.25rem + 2px);
  font-family: inherit;
  color: #383f45;
  font-weight: 300;
}
.fi-field input[type=text],
.fi-field input[type=email],
.fi-field input[type=password],
.fi-field textarea {
  width: 100%;
  height: 100%;
  min-height: calc(2.25rem + 2px);
}
.fi-field.withIco > input,
.fi-field.withIco > textarea,
.fi-field.withIco > select,
.fi-field.withIco > p {
  padding-left: calc(40px + 0.7rem);
}
.fi-field .-ico {
  padding-left: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 100%;
  background: #fff;
  border: #c4c6c6 1px solid;
  border-radius: 0.25rem 0 0 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  font-size: 1.3rem;
  color: #c4c6c6;
}
.fi-field .-icor {
  padding-left: 0;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 100%;
  background: #c4c6c6;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  font-size: 1.3rem;
  border-radius: 0 0.25rem 0.25rem 0;
}
.fi-field .-icor:hover {
  background: #aaadad;
}
.fi-field .popup-msg {
  position: absolute;
  left: 0;
  top: calc(100% + 10px / 2);
  z-index: 1;
}
.fi-field .popup-msg > div {
  background: #ced4da;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  position: relative;
}
.fi-field .popup-msg > div:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ced4da;
  position: absolute;
  left: 10%;
  top: -9px;
  z-index: 20;
}
@media print {
  .fi-field .-ico {
    display: none;
  }
  .fi-field.withIco > * {
    padding-left: 0.75rem !important;
  }
}
.fi-field-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 10px 0;
}
.fi-field-radio label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.fi-field-radio.inline {
  display: inline-flex;
  margin: 0 20px 20px 0;
  width: auto;
}
.fi-field-radio.inline input {
  margin-right: 5px;
}
.fi-field-radio .radio-ico {
  margin: 0 5px;
}
.file-uploader-win input[type=file] {
  margin-bottom: 20px;
}
.form-files-list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.form-files-list li {
  display: inline-flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  width: 150px;
  position: relative;
  border-radius: 5px;
  border: #e5e5e5 1px solid;
  background: #fff;
}
.form-files-list li:first-child {
  margin-left: 0;
}
.form-files-list li a:hover {
  text-decoration: underline;
}
.form-files-list li .fico {
  vertical-align: middle;
  margin-right: 5px;
}
.form-files-list li i {
  color: #758392;
  font-size: 18px;
}
.form-files-list li i:hover {
  cursor: pointer;
  color: #212529;
}
.form-files-list li i.fa-warning {
  color: #e31d24;
}
.form-files-list li figure {
  margin: 0;
  text-align: center;
  max-width: 100%;
}
.form-files-list li figure .thumbnail img {
  max-width: 100%;
  height: 60px;
}
.form-files-list li figure figcaption {
  font-size: 0.8rem;
  margin-top: 5px;
  color: #343A40;
  max-width: 100%;
  overflow: hidden;
  hyphens: auto;
}
.form-files-list li figure figcaption a {
  color: #343A40;
}
.form-files-list li figure > i {
  color: #212529;
  font-size: 3rem;
}
.form-files-list li .react-fancybox .box {
  z-index: 1000;
}
.form-files-list li .ffl-btns {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  border: #e5e5e5 1px solid;
  background: #fff;
  border-radius: 5px;
  visibility: hidden;
}
.form-files-list li .ffl-btns > * {
  margin: 0 10px;
}
.form-files-list li:hover .ffl-btns {
  visibility: visible;
}
.form-chbox-inline {
  display: inline-block;
  margin: 0 10px 10px 0;
}
.smart-select {
  position: relative;
}
.smart-select .label {
  font-weight: bold;
  margin-bottom: 10px;
}
.smart-select .ss-selected {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  min-height: 2.25rem;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
  padding-right: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
.smart-select .ss-selected li {
  display: inline-flex;
  justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
  margin: 3px;
  padding: 3px 5px;
  background: #007BFF;
  color: #fff;
  border-radius: 5px;
}
.smart-select .ss-selected li i {
  margin-left: 5px;
  color: #66b0ff;
}
.smart-select .ss-selected li i:hover {
  color: #fff;
}
.smart-select .ss-selected li.close {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0;
  background: transparent;
}
.smart-select .ss-selected li.close i {
  color: #ced4da;
}
.smart-select .ss-selected li.close i:hover {
  color: #494e53;
}
.smart-select .ss-selected span {
  display: inline-block;
  margin: 3px;
  padding: 3px 5px;
  font-style: italic;
  color: #ced4da;
  background: transparent;
}
.smart-select:hover .fs-selected {
  border-color: #80bdff;
}
.smart-select .ss-pop {
  display: none;
  position: absolute;
  left: 0;
  top: 97%;
  z-index: 10;
  border: #80bdff 1px solid;
  background: #fff;
  width: 100%;
  border-radius: 0 0 0.25rem 0.25rem;
  overflow: hidden;
}
.smart-select .ss-pop.show {
  display: block;
}
.smart-select .ss-srch {
  padding: 6px;
}
.smart-select .ss-srch input {
  border: #80bdff 1px solid;
}
.smart-select .ss-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 82vh;
}
.smart-select .ss-list li {
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.smart-select .ss-list li .im {
  background-color: #f1f1f1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 10px;
}
.smart-select .ss-list li:hover {
  background: #007BFF;
  color: #fff;
  cursor: pointer;
}
.smart-select .ss-list li.selected {
  background: #f1f1f1;
}
.smart-select .ss-list li.selected:hover {
  color: #212529;
}
.smart-select.-one .ss-selected li {
  margin: 3px;
  padding: 3px 5px;
  color: #212529;
  background: transparent;
}
.smart-select.-arrowed .ss-selected:after {
  content: "\f0d7";
  font-size: 1rem;
  color: #ced4da;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  font-family: 'FontAwesome';
}
.smart-select.-arrowed:hover .ss-selected:after {
  color: #212529;
}
.smart-select .popup-msg {
  position: absolute;
  left: 0;
  top: calc(100% + 10px / 2);
  z-index: 1;
}
.smart-select .popup-msg > div {
  background: #ced4da;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  position: relative;
}
.smart-select .popup-msg > div:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ced4da;
  position: absolute;
  left: 10%;
  top: -9px;
  z-index: 20;
}
.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}
.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}
.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}
.rcs-custom-scroll .rcs-inner-container {
  overflow-x: visible;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.rcs-custom-scroll .rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}
.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}
.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}
.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: rgba(78, 183, 245, 0.7);
  border-radius: 3px;
}
@-webkit-keyframes "bounceIn" {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
    transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
    transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
    transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
    transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
    transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
    transform: perspective(1px) scale3d(1, 1, 1);
  }
}
@keyframes "bounceIn" {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
    transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
    transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
    transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
    transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
    transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
    transform: perspective(1px) scale3d(1, 1, 1);
  }
}
@-webkit-keyframes "bounceOut" {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes "bounceOut" {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-webkit-keyframes "fadeIn" {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes "fadeIn" {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes "fadeOut" {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes "fadeOut" {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes "bounceInDown" {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes "bounceInDown" {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes "bounceOutUp" {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes "bounceOutUp" {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.bounceIn {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.animated.bounceOut {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}
.animated.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.animated.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.rrt-confirm-holder .shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(50, 58, 68, 0.8);
}
.rrt-confirm-holder .rrt-confirm {
  width: 320px;
  background-color: white;
  position: absolute;
  z-index: 9;
  top: 20%;
  left: 50%;
  margin-left: -160px;
  box-shadow: 3px 3px 20px #333;
  border-radius: 4px;
  overflow: hidden;
}
.rrt-confirm-holder .rrt-confirm .rrt-message {
  width: 100%;
  padding: 5%;
  min-height: 50px;
  font-size: 1em;
  background-color: white;
  text-align: center;
  font-family: 'open-sanscondensed-light', sans-serif;
  clear: both;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 50px;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  float: left;
  border-top: 1px solid #f0f0f0;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
  background-color: #f5f5f5;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
  outline: none;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
  background-color: #60bb71;
  color: white;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
  background-color: #db6a64;
  color: white;
}
body.toastr-confirm-active {
  overflow: hidden;
}
.redux-toastr * {
  box-sizing: border-box;
}
.redux-toastr *:before {
  box-sizing: border-box;
}
.redux-toastr *:after {
  box-sizing: border-box;
}
.redux-toastr .top-left {
  width: 400px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  top: 0;
  left: 0;
}
.redux-toastr .top-right {
  width: 400px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  top: 55px;
  right: 0;
}
.redux-toastr .top-center {
  min-width: 400px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.redux-toastr .bottom-left {
  width: 400px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  bottom: 0;
  left: 0;
}
.redux-toastr .bottom-right {
  width: 400px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  right: 0;
  bottom: 0;
}
.redux-toastr .bottom-center {
  width: 400px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
  bottom: 0;
  left: 50%;
  margin-left: -175px;
}
.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: 0.94;
}
.redux-toastr .toastr:hover:not(.rrt-message) {
  opacity: 1;
}
.redux-toastr .toastr .toastr-status {
  width: 100%;
  height: 5px;
}
.redux-toastr .toastr .toastr-status.success {
  background-color: #60bb71;
}
.redux-toastr .toastr .toastr-status.warning {
  background-color: #f7a336;
}
.redux-toastr .toastr .toastr-status.info {
  background-color: #58abc3;
}
.redux-toastr .toastr .toastr-status.error {
  background-color: #db6a64;
}
.redux-toastr .toastr .rrt-left-container {
  float: left;
  text-align: center;
  overflow: hidden;
  width: 80px;
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0;
}
.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
  left: 5px;
  line-height: 60px;
}
.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: white;
  vertical-align: middle;
  margin-top: 5px;
}
.redux-toastr .toastr .rrt-right-container {
  float: left;
  text-align: center;
  overflow: hidden;
  width: 10%;
}
.redux-toastr .toastr .rrt-middle-container {
  width: 65%;
  margin-left: 80px;
  position: relative;
  float: left;
  font-size: 1em;
  text-align: left;
  padding: 10px 5px;
}
.redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
}
.redux-toastr .toastr .close-toastr {
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  font-size: 22px;
  border: none;
  outline: none;
  opacity: 0.5;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
}
.redux-toastr .toastr .close-toastr:hover {
  opacity: 1;
}
.redux-toastr .toastr .close-toastr:focus {
  outline: none;
}
.redux-toastr .toastr .rrt-progress-container {
  height: 5px;
  margin: 0 -20px -20px -60px;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
  border-radius: 0 0 0 4px;
  height: 100%;
}
.redux-toastr .toastr.rrt-info {
  color: white;
  background-color: #007BFF;
}
.redux-toastr .toastr.rrt-info .rrt-progressbar {
  background-color: #378298;
}
.redux-toastr .toastr.rrt-success {
  color: white;
  background-color: #28A745;
}
.redux-toastr .toastr.rrt-success .rrt-progressbar {
  background-color: #3e914d;
}
.redux-toastr .toastr.rrt-warning {
  color: white;
  background-color: #FD7E14;
}
.redux-toastr .toastr.rrt-warning .rrt-progressbar {
  background-color: #d87e09;
}
.redux-toastr .toastr.rrt-error {
  color: white;
  background-color: #e31d24;
}
.redux-toastr .toastr.rrt-error .rrt-progressbar {
  background-color: #c5352e;
}
.redux-toastr .toastr.rrt-light .rrt-progressbar {
  background-color: #ccc;
}
.redux-toastr .toastr.rrt-light .toastr-icon {
  fill: #333 !important;
}
.redux-toastr .toastr.rrt-message {
  opacity: 1;
  border: 1px solid #dbdbdb;
}
.redux-toastr .toastr.rrt-message .rrt-title {
  width: 90%;
  height: 50px;
  text-align: center;
  font-size: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 50px;
  padding: 0 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  padding: 15px;
  font-size: 1.1em;
  margin-bottom: 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text img {
  display: block;
  margin: 10px auto;
  max-width: 100%;
}
.redux-toastr .toastr.rrt-message .close-toastr {
  height: 50px;
}
.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
@media (max-width: 320px) {
  .redux-toastr .top-left {
    width: 320px;
  }
  .redux-toastr .top-right {
    width: 320px;
  }
  .redux-toastr .top-center {
    width: 320px;
    margin-left: -160px;
  }
  .redux-toastr .bottom-left {
    width: 320px;
  }
  .redux-toastr .bottom-right {
    width: 320px;
  }
  .redux-toastr .bottom-center,
  .redux-toastr .center-center {
    width: 320px;
    margin-left: -160px;
  }
}
.users-list .-info {
  margin-top: 10px;
  padding-top: 5px;
  border-top: #c4c6c6 1px dotted;
  color: #007BFF;
  font-size: 90%;
}
.users-list .-info p {
  padding: 0;
  margin: 5px 0 0 0;
}
.users-list .-name .im {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
}
.users-list .-name .im img {
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.users-list .-name .im i {
  font-size: 30px;
  color: #ccc;
}
.users-list .-name a {
  color: #212529;
}
.users-list .-name a:hover {
  text-decoration: underline;
}
.user-edit-avatar h3 {
  margin-bottom: 10px;
}
.user-edit-avatar .-av {
  max-width: 200px;
  margin-right: 20px;
}
.user-edit-avatar .-av img {
  width: 100%;
}
.online-users {
  position: fixed;
  right: 0;
  top: 55px;
  background: #fff;
  width: 70%;
  max-height: 80vh;
  overflow: auto;
  border: #000 1px solid;
  display: none;
}
.online-users.show {
  display: block;
}
.online-users .-wrp {
  width: 100%;
  height: 100%;
  position: relative;
}
.online-users .close {
  position: absolute;
  font-size: 1.2rem;
  top: 10px;
  right: 10px;
}
.online-users .close:hover {
  color: #28A745;
  cursor: pointer;
}
.online-users .ou-ua {
  font-size: 0.7rem;
}
.online-users .ou-url {
  font-size: 0.7rem;
}
html {
  box-sizing: border-box;
  moz-box-sizing: border-box;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'Roboto', sans-serif;
  color: #212529;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: #F4F6F9 url(../img/bgr/email-pattern.png);
  background-attachment: fixed;
  height: 100%;
}
@media print {
  body {
    color: #000;
    background: #fff;
  }
}
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}
h1 {
  margin-bottom: 40px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
@media print {
  a {
    color: #000;
    text-decoration: underline;
  }
}
.ml20 {
  margin-left: 20px;
}
textarea {
  resize: none;
  height: calc(10rem + 2px);
  font-size: inherit;
  font-family: inherit;
}
select.tbl-sorter-select {
  padding: 0;
}
select.hidden {
  border: none;
  -webkit-appearance: none;
  appearance: none;
}
select.hidden::-ms-expand {
  display: none;
}
select.hidden option {
  color: #212529;
}
@media print {
  select {
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }
  select:-ms-expand {
    display: none;
  }
}
hr {
  background: #e5e5e5;
  width: 100%;
  height: 1px;
  margin: 20px auto;
  border: 0;
}
.hr {
  background: #212529;
  width: 100%;
  height: 2px;
  margin: 20px auto;
  border: 0;
}
.LoadingBar {
  z-index: 10000;
  height: 5px;
}
.main-wrap {
  width: 100%;
  height: 100%;
}
main {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.content {
  width: 100%;
  padding: 20px 20px 100px 20px;
  margin-left: auto;
  margin-right: auto;
}
.content h1,
.content .h1 {
  font-size: 1.8rem;
}
.content-header {
  margin-bottom: 40px;
}
.content-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
}
.content-title > * {
  margin: 0 10px 0 0;
}
.content-title > a span {
  color: #494e53;
}
.content-title > a span:hover {
  text-decoration: underline;
}
.content-title > a:after {
  content: "/";
  padding: 0 10px;
  color: #758392;
}
.content-title h1 {
  line-height: 90%;
}
@media print {
  .content-title a {
    text-decoration: none;
  }
  .content-title a:first-child {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
.ms-top {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  height: 55px;
  border-bottom: #626d78 1px solid;
  align-items: center;
  padding: 0 20px;
  background: #343A40;
  width: 75px;
  transition: all 200ms;
}
.ms-top.open {
  width: 250px;
}
.ms-top:not(.open) {
  font-size: 10px;
}
@media print {
  .ms-top {
    display: none;
  }
}
.logo a {
  color: #FD7E14;
  font-weight: bold;
}
.menu-bars {
  font-size: 2rem;
  color: #c2c7d0;
  transition: all 200ms;
}
.menu-bars:hover {
  cursor: pointer;
  color: #fff;
}
.mobi-user {
  display: none;
}
.menu-side {
  position: fixed;
  top: 55px;
  z-index: 20;
  width: 75px;
  height: 100%;
  background: #343A40;
  box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.5);
  transition: width 200ms;
}
.menu-side hr {
  border-bottom: #626d78 1px solid;
  height: 0px;
  margin: 10px 0;
}
.menu-side .mCSB_container,
.menu-side .mCustomScrollBox {
  overflow: visible !important;
}
.menu-side:not(.open) .menu-side-scroll {
  margin-left: -8px;
}
@media print {
  .menu-side {
    display: none;
  }
}
.menu-side-scroll {
  overflow: visible !important;
}
.menu-side-scroll > div:first-child {
  overflow: visible !important;
}
.menu {
  padding: 0 20px;
}
.menu ul > li {
  margin: 3px 0;
  position: relative;
}
.menu ul > li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #c2c7d0;
  padding: 10px 0px;
  border-radius: 4px;
  transition: all 200ms;
  outline: none;
}
.menu ul > li > a i {
  font-size: 0.95rem;
  width: 16px;
  text-align: center;
}
.menu ul > li > a.active {
  background: #007BFF;
  color: #fff;
}
.menu ul > li:not(.as-title):hover > a,
.menu ul > li:not(.as-title):hover > a {
  background: #494e53;
  color: #fff;
}
.menu ul > li:not(.as-title):hover > .menu-sub,
.menu ul > li:not(.as-title):hover > .menu-sub {
  display: block;
}
.menu ul > li.active > a {
  background: #007BFF;
  color: #fff;
}
.menu-sub {
  border-right: #343A40 1px solid;
  border-bottom: #343A40 1px solid;
}
.menu-sub a {
  font-size: 0.8rem;
  color: #fff !important;
}
.menu-sub.fixed {
  display: block;
  padding-left: 20px;
}
.menu-sub.pup {
  display: none;
  position: absolute;
  left: 98%;
  top: 0;
  z-index: 30;
  background: #494e53;
  white-space: nowrap;
  border-radius: 4px;
}
.menu-sub.pup li {
  display: block;
  padding: 0 5px;
}
.menu-sub.pup li:hover > a {
  background: #343A40 !important;
}
.top-bar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  width: calc(100% - 250px);
  min-height: 55px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  -ms-flex-pack: justify;
  padding: 0px 20px;
  transition: width 200ms;
}
.top-bar.full {
  width: 100%;
}
.tb-menu li {
  margin-right: 10px;
}
.tb-menu li a {
  color: #212529;
  font-size: 0.8rem;
}
.tb-menu li a:hover {
  text-decoration: underline;
}
.tb-menu li i.fa {
  margin-right: 5px;
}
.tb-menu li:not(:last-child) {
  padding-right: 10px;
  border-right: #e5e5e5 1px solid;
  margin-right: 10px;
}
.tb-menu .show-hover {
  position: fixed;
  top: -1000px;
}
.tb-menu:hover .show-hover {
  position: static;
}
.tb-srch {
  position: relative;
  background: #f1f1f1;
  border-radius: 5px;
}
.tb-srch input {
  width: 80%;
  border: 0;
  background: transparent;
}
.tb-srch button {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #343A40;
}
.tb-srch button:hover i {
  color: #007BFF;
}
.tb-srch button:active {
  margin-top: 0.5px;
  right: 9.5px;
}
.tb-srch button:active i {
  color: #007BFF;
}
.tb-srch > i {
  font-size: 0.8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  color: #bebebe;
}
.tb-srch.hidden {
  display: none;
}
.tb-notifs > a {
  position: relative;
  margin: 0 15px;
}
.tb-notifs > a i {
  font-size: 1.3rem;
  color: #212529;
}
.tb-notifs > a .count {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #e31d24;
  color: #fff;
  font-size: 0.7rem;
  padding: 1px 2px;
  border-radius: 2px;
}
.tb-notifs > a:hover i {
  color: #007BFF;
}
.topbar-notify {
  position: relative;
}
.topbar-notify .ico {
  position: relative;
}
.topbar-notify .ico i {
  color: #343A40;
  font-size: 24px;
}
.topbar-notify .ico span {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex-pack: center;
  font-size: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.topbar-notify-list {
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  width: 400px;
  box-shadow: 0 0 1px #e5e5e5, 0 1px 3px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  overflow-y: auto;
  display: none;
}
.topbar-notify-list.active {
  display: block;
}
.topbar-notify-list > li {
  padding: 10px 0;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.topbar-notify-list > li .t {
  flex: 1;
  padding: 0 5px 0 15px;
}
.topbar-notify-list > li .d {
  width: 25%;
  text-align: center;
  color: #808080;
}
.topbar-notify-list > li .dl {
  width: 10%;
  text-align: center;
  color: #ebebeb;
}
.topbar-notify-list > li .dl i {
  cursor: pointer;
}
.topbar-notify-list > li:nth-child(even) {
  background: #f2f2f2;
}
.topbar-notify-list > li:hover {
  background: #e6e6e6;
}
.topbar-notify-list > li:hover .dl {
  color: #808080;
}
.topbar-notify-list > li.linked {
  cursor: pointer;
}
.tb-user:before {
  content: "";
  width: 1px;
  height: 27.5px;
  background: #f1f1f1;
  margin: 0 20px;
}
.tb-user .im {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center center;
  background-size: cover;
}
.tb-user .im.online {
  border: #28A745 1px solid;
}
.tb-user .n {
  max-width: 140px;
  overflow: hidden;
}
.tb-user .n span {
  color: #ccc;
  font-size: 0.7rem;
}
.tb-user .actions {
  padding-left: 20px;
}
.popup-win {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.popup-win .win {
  max-height: 90vh;
}
.popup-win .win.wide {
  width: 70vw;
}
@media (max-width: 1200px) {
  .popup-win .win.wide {
    width: 80vw;
  }
}
@media (max-width: 800px) {
  .popup-win .win.wide {
    width: 96vw;
  }
}
.popup-win .win.half {
  width: 50vw;
}
@media (max-width: 1200px) {
  .popup-win .win.half {
    width: 80vw;
  }
}
@media (max-width: 800px) {
  .popup-win .win.half {
    width: 96vw;
  }
}
@keyframes animShow {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animScale {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes winHide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}
.win {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -ms-flex-pack: justify;
  box-shadow: 0 0 1px #e5e5e5, 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 5px;
}
.win > .h {
  padding: 10px 20px;
  color: #000;
  font-size: 20px;
  border-bottom: #e5e5e5 1px solid;
  border-radius: 5px 5px 0 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.win > .h .b {
  display: inline-block;
  margin-left: 15px;
}
.win > .h .b:before {
  color: #758392;
  transition: all 200ms;
}
.win > .h .b:hover:before {
  color: #000;
  cursor: pointer;
}
.win > .h .b.space {
  width: 15px;
}
.win > .h .minimize {
  color: #758392;
}
.win > .h .minimize:before {
  content: "\f2d1";
}
.win > .h .minimize.active:before {
  content: "\f2d0";
}
.win > .h .minimize:hover {
  cursor: pointer;
  color: #000;
}
.win > .c {
  overflow-y: auto;
  border-radius: 5px;
}
.win > .c .-wrap {
  padding: 20px;
}
.win > .c.pad {
  padding: 20px;
}
.win > .c.hide {
  display: none;
}
.win > .c.-no-overflow {
  overflow: visible;
}
.win > .f {
  padding: 10px 20px;
  background: #f1f1f1;
  border-radius: 0 0 5px 5px;
}
.win.-green {
  background: #28A745;
}
.win.-green > .h {
  border-bottom: none;
  color: #fff;
}
.win.-green > .f {
  background: #228E3B;
}
.win.-green > .f a {
  color: #fff;
}
.win.note {
  border-left: #e31d24 5px solid;
}
.win.-grey {
  background: #f1f1f1;
}
.win.-clean {
  box-shadow: none;
  background: transparent;
}
.win .sub-win {
  background: #f1f1f1;
  border-radius: 5px;
  padding: 20px;
}
.win.minimized {
  overflow: hidden;
}
.win.minimized > .h {
  border-bottom: 0;
}
.win.only-footer > .f {
  border-radius: 5px;
}
.win.hdr-blue > .h {
  background: #007BFF;
  color: #fff;
}
.win.hdr-blue > .h .fa:before {
  color: #fff !important;
}
.win.hdr-green > .h {
  background: #28A745;
  color: #fff;
}
.win.hdr-green > .h .fa:before {
  color: #fff !important;
}
.win.hdr-grey > .h {
  background: #f1f1f1;
}
.win-wide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
}
.win-ftr-btns .btn:not(:first-child) {
  margin-left: 20px;
}
.btn {
  display: inline-flex;
  padding: 10px 20px;
  color: #fff;
  background: #007BFF;
  border-radius: 5px;
  transition: all 200ms;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}
.btn:hover,
.btn:active {
  background: #3395ff;
}
.btn.-green {
  background: #28A745;
}
.btn.-green:hover,
.btn.-green:active {
  background: #34ce57;
}
.btn.-grey {
  background: #494e53;
}
.btn.-grey:hover,
.btn.-grey:active {
  background: #343A40;
}
.btn.-lgrey {
  background: #caced1;
}
.btn.-lgrey:hover,
.btn.-lgrey:active {
  background: #949ba1;
}
.btn.-red {
  background: #e31d24;
}
.btn.-red:hover,
.btn.-red:active {
  background: #e94a50;
}
.btn.-small {
  padding: 5px 10px;
  font-size: 0.8rem;
}
.btn.disabled {
  opacity: 0.1;
}
.btn .-in {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.btn .-in i {
  margin-right: 10px;
}
.btn .-in.emptyTitle i {
  margin-right: 0;
}
.btn .tip {
  position: absolute;
  padding: 5px;
  background: #1d2124;
  color: #fff;
  top: 101%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  border-radius: 3px;
  transition: all 200ms;
  min-width: 100%;
  white-space: nowrap;
  opacity: 0;
}
.btn .tip.active {
  opacity: 1;
}
.btn.-icon-only .-in i {
  margin-right: 0;
}
.tbl {
  border-collapse: collapse;
  border-left: #e5e5e5 1px solid;
  border-top: #e5e5e5 1px solid;
  border-right: #e5e5e5 1px solid;
  width: 100%;
}
.tbl tr,
.tbl .tr {
  background: #fff;
}
.tbl .tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.tbl td,
.tbl .td {
  border-bottom: #e5e5e5 1px solid;
  padding: 10px;
  hyphens: auto;
}
.tbl td.-bgr,
.tbl .td.-bgr {
  background: #f1f1f1;
}
.tbl td.editable:after,
.tbl .td.editable:after {
  font-family: "FontAwesome";
  content: "\f14b";
  position: absolute;
  top: 1px;
  right: 1px;
  color: #007BFF;
  font-size: 0.8rem;
  opacity: 0;
}
.tbl td.editable:hover:after,
.tbl .td.editable:hover:after {
  opacity: 1 !important;
}
.tbl td.sortable,
.tbl .td.sortable {
  cursor: pointer;
}
.tbl td.editText,
.tbl .td.editText {
  padding: 0;
}
.tbl td.editText .editor,
.tbl .td.editText .editor {
  padding: 10px;
  white-space: pre-wrap;
  outline: none;
  width: 100%;
  height: 100%;
}
.tbl .td {
  position: relative;
}
.tbl thead,
.tbl .thead {
  font-weight: bold;
}
.tbl thead select,
.tbl .thead select {
  font-weight: bold;
}
.tbl thead select option,
.tbl .thead select option {
  font-weight: 400;
}
.tbl .thead > .tr {
  align-items: stretch;
}
.tbl .thead > .tr .td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.tbl.hdr-cntr thead td {
  text-align: center;
}
.tbl.hdr-cntr .thead .td {
  text-align: center;
  justify-content: space-around;
}
.tbl.hdr-cntr .td-title {
  text-align: center;
}
.tbl tfoot {
  background: #f1f1f1;
}
.tbl tbody td {
  position: relative;
  background-clip: padding-box;
}
.tbl.dark thead td,
.tbl.dark .thead td,
.tbl.dark thead .td,
.tbl.dark .thead .td {
  background: #f1f1f1;
  border-bottom: #e5e5e5 1px solid;
}
.tbl.dark thead td:last-child,
.tbl.dark .thead td:last-child,
.tbl.dark thead .td:last-child,
.tbl.dark .thead .td:last-child {
  border-right: #e5e5e5 1px solid;
}
.tbl.-blue thead td,
.tbl.-blue .thead td,
.tbl.-blue thead .td,
.tbl.-blue .thead .td {
  background: #007BFF;
  color: #fff;
  border-bottom: #e5e5e5 1px solid;
}
.tbl.-blue thead td:last-child,
.tbl.-blue .thead td:last-child,
.tbl.-blue thead .td:last-child,
.tbl.-blue .thead .td:last-child {
  border-right: #e5e5e5 1px solid;
}
.tbl.-bordered .td:not(:last-child) {
  border-right: #e5e5e5 1px solid;
}
.tbl.-zebra tbody tr:nth-child(even),
.tbl.-zebra .tbody tr:nth-child(even),
.tbl.-zebra tbody .tr:nth-child(even),
.tbl.-zebra .tbody .tr:nth-child(even) {
  background: #fafafa;
}
.tbl.-zebra tbody tr:hover td,
.tbl.-zebra .tbody tr:hover td,
.tbl.-zebra tbody .tr:hover td,
.tbl.-zebra .tbody .tr:hover td,
.tbl.-zebra tbody tr:hover .td,
.tbl.-zebra .tbody tr:hover .td,
.tbl.-zebra tbody .tr:hover .td,
.tbl.-zebra .tbody .tr:hover .td {
  background: #f6f6f6;
}
.tbl.-light thead td,
.tbl.-light .thead td,
.tbl.-light thead .td,
.tbl.-light .thead .td {
  background: #e5e5e5;
  color: #494e53;
}
.tbl.-light td.st {
  background: #ffffcc;
}
.tbl.-light td.su {
  background: #ffb3b3;
}
@media print {
  .tbl {
    border-left: #000 1px solid;
    border-top: #000 1px solid;
    border-right: #000 1px solid;
  }
  .tbl .tr {
    background: #fff !important;
    border-top: #000 1px solid;
  }
  .tbl td,
  .tbl .td {
    border-bottom: #000 1px solid;
    position: static !important;
  }
  .tbl td.editable:after,
  .tbl .td.editable:after {
    display: none;
  }
  .tbl .td-title {
    display: none !important;
  }
  .tbl.dark .thead .td {
    border-right: #000 1px solid;
    border-bottom: #000 1px solid;
  }
  .tbl.-blue .thead .td {
    background: #f1f1f1;
    color: #212529;
  }
  .tbl.-bordered .td {
    border-right: #000 1px solid !important;
  }
  .tbl .tbody .tr {
    border-top: #000 1px solid;
  }
}
.tbl-sort {
  display: inline-block;
  font-size: 1.1rem;
  margin-left: 10px;
  color: #ccc;
}
.tbl-sort:hover {
  color: #007BFF;
}
.tbl-sort.active {
  color: #000;
}
.actions {
  position: relative;
}
.actions i {
  font-size: 1.2rem;
  color: #aeb6bf;
}
.actions > div {
  display: none;
  position: absolute;
  top: 20px;
  z-index: 10;
  background: #fff;
  border: #e5e5e5 1px solid;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
}
.actions > div > * {
  display: block;
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  color: #212529;
  align-items: center;
}
.actions > div > * i {
  margin-right: 10px;
  font-size: 0.95rem;
}
.actions > div > *:hover {
  background: #f2f2f2;
  color: #000;
}
.actions > div > *:hover i {
  color: #000;
}
.actions > div:hover {
  display: block;
}
.actions:hover > i {
  color: #758392;
}
.actions.open > div {
  display: block;
}
.actions.-left div {
  left: 0;
}
.actions.-right div {
  right: 0;
}
.login-win {
  min-width: 320px;
}
.login-win a {
  text-decoration: underline;
}
.panel-top .btn {
  margin-left: 5px;
  margin-right: 5px;
}
.scroll-top-btn {
  position: fixed;
  bottom: 1vh;
  right: 1vw;
  z-index: 100000;
  font-size: 2rem;
  color: #ccc;
  display: none;
  transition: all 200ms;
}
.scroll-top-btn.active {
  display: block;
}
.scroll-top-btn:hover {
  color: #212529;
  cursor: pointer;
}
fieldset {
  border: #e5e5e5 1px solid;
  border-radius: 10px;
}
fieldset legend {
  color: #28A745;
}
.code {
  border: #000 1px solid;
}
.tip {
  position: relative;
  display: inline-block;
}
.tip .show .tip-popup {
  display: block;
  opacity: 1;
}
.tip-popup {
  position: absolute;
  padding: 5px;
  background: #1d2124;
  color: #fff;
  top: 101%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  border-radius: 3px;
  min-width: 100%;
  white-space: nowrap;
  display: none;
  opacity: 0;
  z-index: 1;
  transition: all 200ms;
}
.page-404 {
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex-pack: center;
  font-size: 6rem;
  text-align: center;
}
.logs-tbl .col-date {
  width: 12%;
  text-align: center;
}
.logs-tbl .col-txt {
  width: 68%;
}
.logs-tbl .col-txt.-long {
  font-size: 0.8rem;
}
.logs-tbl .col-ltype {
  width: 10%;
  text-align: center;
}
.logs-tbl .col-stype {
  width: 10%;
  text-align: center;
}
.logs-tbl .log-10 {
  background: #f98782;
}
.logs-tbl .log-15 {
  background: #000;
  color: #fff;
}
.projects-list {
  background: #fff;
}
.projects-list .col-act {
  width: 3%;
}
.projects-list .col-name {
  width: 30%;
}
.projects-list .col-name a {
  color: inherit;
}
.projects-list .col-name a:hover {
  text-decoration: underline;
}
.projects-list .col-sites {
  flex: 1;
}
.projects-list .col-owner {
  width: 15%;
}
.projects-list .pl-site {
  margin: 5px;
  color: #212529;
}
.projects-list .pl-name-descr {
  margin-top: 1rem;
  color: #494e53;
  font-size: 0.8rem;
}
.projects-list .unpublish {
  opacity: 0.5;
}
.projects-list .unpublish:hover {
  opacity: 1;
}
.site-access-field .col-name {
  width: 40%;
}
.site-access-field .col-val {
  flex: 1;
  padding-left: 20px;
}
.site-access-field .col-del {
  width: 3%;
  text-align: right;
  padding-top: 30px;
}
.site-access-field .col-del i:hover {
  color: #FD7E14;
  cursor: pointer;
}
.tasks-list .thead .td {
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
}
.tasks-list .thead .td.col-name {
  text-align: left;
  justify-content: flex-start;
}
.tasks-list .col-act {
  flex: 0.2;
}
.tasks-list .col-name {
  flex: 7.5;
}
.tasks-list .col-weight {
  flex: 1;
}
.tasks-list .col-status {
  flex: 1;
}
.tasks-list .col-created {
  width: 80px;
}
.tasks-list .col-end_date {
  flex: 1;
}
.tasks-list .col-exec {
  flex: 1.5;
}
.tasks-list .col-pid {
  flex: 1.5;
}
.tasks-list .tbody .td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.tasks-list .tbody .td h5 {
  text-align: center;
}
.tasks-list .tbody .cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex-pack: center;
  flex: 1;
}
.tasks-list-item.draft .tl-title {
  color: rgba(0, 0, 0, 0.3);
}
.tl-title {
  color: #212529;
  font-size: 1.05rem;
}
.tl-title:hover {
  text-decoration: underline;
}
.tl-status {
  display: inline-block;
  padding: 5px;
  color: #000;
  font-size: 0.8rem;
  background: #f1f1f1;
  border-radius: 2px;
  text-align: center;
  hyphens: auto;
  white-space: normal;
  /*
	static STATUS = {
		0: 'новая',
		5: 'в работе',
		10: 'протестировать',
		12: 'есть вопросы',
		15: 'готово',
		20: 'просрочена',
		25: 'удалена',
	}
	 */
}
.tl-status.-s5 {
  background: yellow;
}
.tl-status.-s10 {
  background: #007BFF;
  color: #fff;
}
.tl-status.-s12 {
  background: #d77777;
  color: #fff;
}
.tl-status.-s14 {
  background: orange;
  color: #fff;
}
.tl-status.-s15 {
  background: #28A745;
}
.tl-status.-s20 {
  background: #e31d24;
  color: #fff;
}
.tl-status.-s25 {
  background: #000;
  color: #fff;
}
.tlt-inf {
  margin-top: 20px;
  font-size: 0.7rem;
  color: #758392;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.tlt-inf > div {
  margin-right: 30px;
}
.tlt-inf .cmnts {
  white-space: nowrap;
}
.tlt-inf .cmnts i {
  margin-right: 5px;
}
.tlt-inf .cmnts span span {
  color: #007BFF;
}
.task-edit-btns .-all {
  flex: 1;
}
.task-edit-btns .-del {
  text-align: right;
}
.task-part-add {
  cursor: pointer;
}
.task-page .file-uploader-win .form-item {
  margin: 0;
}
.task-page .file-uploader-win > .c .-wrap {
  padding: 20px 0 0 0;
}
.task-txt {
  color: #353c42;
}
.task-txt ul {
  margin: 20px 0 20px 20px;
  padding: 0;
}
.task-txt ul li {
  list-style: disc;
  color: #353c42;
}
.task-txt a {
  color: #007BFF;
  text-decoration: underline;
}
.task-txt a:active,
.task-txt a:hover {
  color: #28A745;
}
.task-txt a:visited {
  color: #65717e;
}
.task-txt blockquote {
  padding: 10px 0 10px 10px;
  margin: 0;
  border-left: #494e53 4px solid;
  background: #f1f1f1;
  border-radius: 5px;
  color: #353c42;
}
.task-txt blockquote p {
  padding: 0;
  margin: 5px 0;
}
.task-txt pre {
  padding: 10px;
  border: #c4c6c6 1px solid;
  font-family: monospace, sans-serif;
  color: #353c42;
}
.task-txt pre p {
  padding: 0;
  margin: 5px 0;
}
.task-title > div:first-child {
  max-width: 95%;
}
.task-weight {
  padding: 5px;
  border-radius: 5px;
  background: #28A745;
  font-size: 0.8rem;
  margin-right: 20px;
}
.task-show-btm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
  background: #f1f1f1;
  margin-top: 30px;
  padding: 0 20px;
}
.task-show-btm .controls {
  padding: 20px 0;
}
.task-show-btm .controls > * {
  transition: all 200ms;
}
.task-show-btm .controls > *.active {
  color: green;
}
.task-show-btm .controls > *:hover {
  color: #000;
  cursor: pointer;
}
.task-info-item {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.task-info-item label {
  margin-right: 10px;
}
.task-info-item > div {
  flex: 1;
}
.task-info-show-site-info {
  display: inline-block;
  font-size: 0.9rem;
  margin-left: 20px;
  padding: 3px;
  border: #494e53 1px dotted;
  cursor: pointer;
}
.task-info-show-site-info i {
  margin-right: 5px;
}
.task-site-info {
  font-size: 0.9rem;
  border: #e5e5e5 1px solid;
  padding: 0 10px;
  max-height: 500px;
  overflow-y: auto;
}
.task-site-info > * {
  margin: 10px 0;
}
.task-site-info h3 {
  margin: 0 0 5px 0;
  font-size: 1rem;
}
.task-site-info p {
  margin: 5px 0;
}
.task-site-info p span {
  color: #758392;
}
.comments .win {
  border-top: #cccccc 2px solid;
}
.comments .win:hover .controls {
  opacity: 1;
}
.comments .win.accent {
  border-color: blue;
}
.comments .win.new {
  border-color: red;
}
.comments .win.scrolled .h {
  background: #f1f1f1;
}
.comments .comment-add {
  margin-bottom: 1rem;
}
.comments .nm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.comments .nm .avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  background: #f1f1f1;
  margin-right: 15px;
}
.comments .nm .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.comments .nm .avatar i {
  color: #fff;
  font-size: 22px;
}
.comments .nm span {
  font-size: 0.8rem;
  color: #758392;
  margin-left: 15px;
}
.comments .nm .lnk {
  margin: 0 20px;
}
.comments .nm .lnk i {
  font-size: 16px;
  color: #ccc;
}
.comments .controls {
  opacity: 0;
  transition: all 200ms;
}
.comments .controls a {
  color: #758392;
  font-size: 0.8rem;
  display: inline-block;
  margin-left: 20px;
}
.comments .controls a:hover {
  color: #000;
}
.comments .c p {
  margin: 10px 0;
}
.comment-item.pinned {
  border-left: #FD7E14 5px solid;
}
.comment-hdr {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
}
.comment-files .win {
  border-top: 0;
}
.comment-files .file-uploader-win {
  margin-bottom: 0;
}
.comment-files .file-uploader-win .-wrap {
  padding: 0;
}
.comment-files .file-uploader-win .form-item {
  margin-bottom: 0;
}
.comment-files .-open span {
  color: #007BFF;
  border-bottom: #007BFF 1px dotted;
  cursor: pointer;
}
.comment-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: #f1f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 1px #e5e5e5, 0 1px 3px rgba(0, 0, 0, 0.2);
}
.comment-add .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  background: #fff;
}
.comment-add .avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px #fff solid;
}
.comment-add .avatar i {
  color: #bebebe;
  font-size: 26px;
}
.comment-add.-placeholder {
  padding: 10px 20px;
}
.comment-add.-placeholder .like-input {
  border: #e5e5e5 1px solid;
  flex: 1;
  background: #fff;
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px;
  color: #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.comment-add .wrp {
  flex: 1;
  margin-left: 20px;
}
.comment-add .btns .btn {
  margin-right: 60px;
}
.comment-add .btns a {
  color: #ccc;
}
.comment-add .btns a:hover {
  color: #212529;
}
.comment-children {
  padding-left: 5vw;
}
.tasks-filter {
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.tasks-filter > * {
  width: 100%;
  padding: 0 10px 0 0;
}
.tasks-filter-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.tasks-filter-title span {
  margin: 0 10px;
  font-size: 0.9rem;
}
@media screen and (min-width: 1200px) {
  .tasks-filter > * {
    width: 49%;
    margin-bottom: 10px;
  }
  .tasks-filter > .col-status {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .tasks-filter > * {
    width: 33% !important;
  }
}
@media (max-width: 450px) {
  .tlt-inf {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .tlt-inf > div {
    margin: 5px 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  body {
    padding-top: 55px;
  }
  .top-bar.full {
    padding-left: 95px;
  }
  .top-bar .menu-bars {
    color: #212529;
    margin-right: 20px;
    font-size: 1.2rem;
  }
  .tb-srch {
    width: 30%;
  }
  main {
    padding: 0 0 0 250px;
  }
  main.full {
    padding-left: 75px;
  }
  .ms-top .menu-bars {
    display: none;
  }
  .menu {
    margin-top: 5vh;
  }
  .menu-side .menu > ul > li > a {
    justify-content: center;
    -ms-flex-pack: center;
  }
  .menu-side .menu > ul > li > a > span {
    display: none;
  }
  .menu-side .menu-sub > li > a i {
    margin-right: 20px;
  }
  .menu-side.open {
    width: 250px;
  }
  .menu-side.open .menu ul > li > a {
    padding-left: 15px;
    padding-right: 15px;
    justify-content: flex-start;
  }
  .menu-side.open .menu ul > li > a i {
    margin-right: 20px;
  }
  .menu-side.open .menu ul > li > a > span {
    display: block;
  }
  .tbl .td-title {
    display: none;
  }
}
@media (max-width: 1024px) {
  .tbl .thead {
    display: none;
  }
  .tbl .td-title {
    display: block;
    margin: -10px -10px 20px -10px;
    padding: 5px;
    background: #f1f1f1;
    font-size: 1rem;
    color: #212529 !important;
    font-weight: bold;
  }
  .tbl .td-title br {
    display: none;
  }
  .tbl .tbody .tr {
    border-top: #212529 5px solid;
  }
  .tbl .td {
    width: 50%;
  }
  .tbl .sub-hdr {
    width: 100%;
  }
  .row .col-2,
  .row .col-3,
  .row .col-2-3 {
    width: 100%;
  }
  .ms-top {
    width: 100%;
  }
  .ms-top.open {
    width: 100%;
  }
  .menu-side {
    width: 0;
  }
  .menu-side .menu {
    width: 0;
    display: none;
  }
  .menu-side .menu ul > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu-side .menu ul > li > a > i {
    margin-right: 20px;
  }
  .menu-side.mobi-open {
    width: 80%;
  }
  .menu-side.mobi-open .menu {
    display: block;
    width: 100%;
  }
  .menu-side.mobi-open .mobi-user {
    display: block;
  }
  .top-bar {
    display: none;
  }
  main {
    padding-top: 55px;
  }
  .js-scrolled-h {
    overflow-x: auto;
  }
  .content {
    padding: 20px 5px;
  }
  .mobi-user {
    padding: 10px 20px;
  }
  .mobi-user .tb-user:before {
    display: none;
  }
  .mobi-user .tb-user .im i {
    color: yellow;
  }
  .mobi-user .tb-user .n {
    color: #fff;
    flex: 1;
  }
  .mobi-user .tb-user .actions {
    width: 30px;
  }
  .topbar-notify-list {
    max-width: 98vw;
  }
}
@media (max-width: 450px) {
  .tbl td {
    padding: 5px;
  }
  .content {
    padding: 20px 5px;
  }
  .content h1 {
    font-size: 1.2rem;
  }
  .panel-top {
    margin-top: 20px;
  }
  .panel-top .btn {
    margin-bottom: 5px;
  }
  .panel-top .-dates {
    font-size: 0.9rem;
  }
  .panel-top .panel-top-dates-item {
    width: 35%;
  }
  .panel-top .panel-top-dates-item input {
    font-size: 0.9rem;
  }
  .mobile-mt20 {
    margin-top: 20px;
  }
  .win-ftr-btns .btn {
    margin: 5px !important;
  }
  .win .h .win-ftr-btns {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .win .h .win-ftr-btns .btn {
    margin: 5px !important;
  }
  .win .h .win-ftr-btns .btn:nth-child(2) {
    display: none;
  }
}
@media print {
  @page {
    size: auto;
    padding: 0mm;
    margin: 0mm 0mm;
  }
  body {
    font-size: 8pt !important;
  }
  a {
    text-decoration: none !important;
  }
  .no-print {
    display: none;
  }
  .btn {
    display: none;
  }
  main {
    padding: 0;
    display: block;
  }
  .content {
    padding: 0px;
    width: 100% !important;
  }
  .win {
    box-shadow: none;
  }
  .win > .h .fa-close {
    display: none;
  }
  .win > .f {
    display: none;
  }
  .win > .c .-wrap {
    padding: 20px 0;
  }
  input,
  select {
    border: none !important;
    border-bottom: #e5e5e5 1px solid !important;
    border-radius: 0px !important;
  }
  textarea {
    border-radius: 0px !important;
    border: none !important;
    border-left: #e5e5e5 2px solid !important;
  }
}
@media not print {
  #root {
    height: 100%;
  }
  .print-only {
    display: none;
  }
  .anim-show {
    animation: 200ms ease-out 0s 1 animShow;
  }
  .win-hide {
    animation: 200ms ease-out 0s 1 winHide;
  }
  .anim-win {
    animation: 200ms ease-out 0s 1 animShow, 200ms ease-out 0s 1 animScale;
  }
}
