@bodyBgr: #F4F6F9;

@greyBgr: #343A40;
@greyBgrHover: #494e53;
@greyBgrLineClr: lighten(@greyBgr, 20%);
@greyBgrDark: darken(@greyBgr, 10%);
@greenClr: #28A745;
@orangeClr: #FD7E14;
@redClr: #e31d24;
@borderClr: #c4c6c6;
@borderClrPrint: #000;
@borderClrDisabled: #f1f1f1;

@blueClr: #007BFF;
@inputBorderClr: #ced4da;
@inputBorderClrHover: #80bdff;



@menuLinkClr: #c2c7d0;
@menuLinkClrHover: #fff;

@topBarSrchBgr: #f1f1f1;

@winLineClr: #e5e5e5;//rgba(0, 0, 0, 0.125);

@borderColor: @winLineClr;
@borderColorHover: #80bdff;

//текст
@txtClr: #212529;//212529
@txtLightClr: #758392;
@fontSize: 16px;
@fontSizePrint: 8pt;

//////////размеры
@menuSideWidth: 250px;
@menuSideWidthClose: @menuSideWidth * 0.3;
@topBarHeight: 55px;

@menuPaddingLR: 20px;

@winPadLR: 20px;

///////
@transitionsTime: 200ms;

@desktop: ~"(min-width: 1024px)";
@mobileMiddle: ~"(min-width: 600px)";
@mobile: ~"(max-width: 1024px)";
@mobileSmall: ~"(max-width: 450px)";

/// z-index
@zTop: 500;


@winMarginBtm: 1rem;
.winShadow() {
	box-shadow: 0 0 1px @winLineClr, 0 1px 3px rgba(0, 0, 0, .2);
}
