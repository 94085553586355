@import 'mixins.less';
@import 'config.less';

.tasks-filter {

}

.tasks-list {

	.thead {
		.td {
			//.bl(#ccc);
			//.br(#ccc);
			.flexCntr;
			.tCenter;

			&.col-name {
				.tLeft;
				justify-content: flex-start;
			}
		}
	}

	.col-act {
		//.w(3%);
		flex: 0.2;
	}

	.col-name {
		//.w(62%);
		flex: 7.5;
	}

	.col-weight {
		//.w(5%);
		flex: 1;
	}

	.col-status {
		//.w(10%);
		flex: 1;
	}

	.col-created {
		.w(80px);
		//flex: 1;
	}

	.col-end_date {
		//.w(10%);
		flex: 1;
	}

	.col-exec {
		flex: 1.5;
	}

	.col-pid {
		flex: 1.5;
	}

	.tbody {
		.td {
			.dFlex;
			.flexColumn;

			h5 {
				.tac;
			}
		}

		.cont {
			.flexInCntr;
			flex: 1;
		}

	}
}

.tasks-list-item {
	//.bl(#fff, 1px);
	&.draft {
		//border-color: red;
		//.bgr(#ccc);
		//opacity: .5;
		.tl-title {
			.clr(rgba(0, 0, 0, 0.3));
		}
	}
}

.tl-title {
	//.clr(#000);
	//.bold;
	.clr(@txtClr);
	.fs(1.05rem);

	&:hover {
		.tdUnd;
	}
}

.tl-status {
	.dInline;
	.p(5px);
	.clr(#000);
	.fs(0.8rem);
	.bgr(#f1f1f1);
	.baradius(2px);
	.tac;
	hyphens: auto;
	white-space: normal;
	//.w(80px);

	/*
	static STATUS = {
		0: 'новая',
		5: 'в работе',
		10: 'протестировать',
		12: 'есть вопросы',
		15: 'готово',
		20: 'просрочена',
		25: 'удалена',
	}
	 */
	//новая
	&.-s5 {
		.bgr(yellow);
	}

	&.-s10 {
		.bgr(@blueClr);
		.clr(#fff);
	}

	&.-s12 {
		.bgr(#d77777);
		.clr(#fff);
	}

	&.-s14 {
		.bgr(orange);
		.clr(#fff);
	}
	&.-s15 {
		.bgr(@greenClr);
	}

	&.-s20 {
		.bgr(@redClr);
		.clr(#fff);
	}

	&.-s25 {
		.bgr(#000);
		.clr(#fff);
	}
}

.tlt-inf {
	.mt(20px);
	.fs(0.7rem);
	.clr(@txtLightClr);
	.dFlex;

	> div {
		.mr(30px);
	}

	span {
		//.fs(0.8rem);
	}

	.cmnts {
		.noWrap;

		i {
			.mr(5px);
		}

		span {
			span {
				.clr(@blueClr);
			}
		}
	}
}

//.task-parts {
//	 > li {
//
//	 }
//}

.task-edit-btns {
	.-all {
		flex: 1;
	}

	.-del {
		.tRight;
	}
}


.task-part-add {
	.pointer;
}

////// просмотр задачи
.task-page {
	.file-uploader-win {
		.form-item {
			.m(0);
		}

		> .c .-wrap {
			.p(20px 0 0 0);
		}
	}
}

.task-txt {
	@thisClr: #353c42;
	.clr(@thisClr);

	ul {
		margin: 20px 0 20px 20px;
		.p(0);

		li {
			list-style: disc;
			.clr(@thisClr);
		}
	}

	a {
		.clr(@blueClr);
		.tdUnd;

		&:active, &:hover {
			.clr(@greenClr);
		}

		&:visited {
			.clr(lighten(@txtClr, 30%));
		}
	}

	blockquote {
		.p(10px 0 10px 10px);
		.m(0);
		.bl(@greyBgrHover, 4px);
		.bgr(#f1f1f1);
		.baradius(5px);
		.clr(@thisClr);

		p {
			.p(0);
			.m(5px 0);
		}
	}

	pre {
		.p(10px);
		.border(@borderClr);
		font-family: monospace, sans-serif;
		.clr(@thisClr);

		p {
			.p(0);
			.m(5px 0);
		}
	}
}

//.task-show-parts {
//	counter-reset: tp-counter;
//	.win {
//		counter-increment: tp-counter;
//		&:after {
//			content: "#" counter(tp-counter);
//			.absolute;
//			top: 10px;
//			right: 10px;
//			//.fs(1rem);
//			.clr(@txtLightClr);
//		}
//	}
//}
.task-title {
	> div {
		&:first-child {
			.wMax(95%);
		}
	}
}

.task-weight {
	.p(5px);
	.baradius(5px);
	.bgr(@greenClr);
	.fs(.8rem);
	.mr(20px);
}

.task-show-btm {
	.dFlex;
	.flexSb;
	align-items: center;
	.bgr(@topBarSrchBgr);
	.mt(30px);
	.p(0 @winPadLR);

	.controls {
		.p(@winPadLR 0);

		> * {

			//.clr(@txtLightClr);
			transition: all @transitionsTime;

			&.active {
				.clr(green);
			}

			&:hover {
				.clr(#000);
				.pointer;
			}

			i {
				//.fs(1.5rem);
			}
		}
	}
}

//.task-info {
//
//}
.task-info-item {
	.mb(15px);
	.dFlex;
	.flexWrap;
	.flexAi;

	label {
		.mr(10px);
	}

	> div {
		flex: 1;
	}
}

.task-info-show-site-info {
	.dInline;
	.fs(0.9rem);
	.ml(20px);
	.p(3px);
	.border(@greyBgrHover, 1px, dotted);
	.pointer;

	i {
		.mr(5px);
	}
}

.task-site-info {
	.fs(0.9rem);
	.border(@borderColor);
	.p(0 10px);
	//.dNone;
	.hMax(500px);
	overflow-y: auto;

	> * {
		.m(10px 0);
	}

	h3 {
		.m(0 0 5px 0);
		.fs(1rem);
	}

	p {
		.m(5px 0);

		span {
			.clr(@txtLightClr);
		}
	}
}

.comments {

	.win {
		.bt(lighten(#000, 80%), 2px);
		//scroll-margin-top: -200px;

		&:hover {
			.controls {
				opacity: 1;
			}
		}

		&.accent {
			border-color: blue;
		}
		&.new {
			border-color: red;
		}
		&.scrolled {
			.h {
				.bgr(#f1f1f1);
			}
		}
	}

	.comment-add {
		.mb(@winMarginBtm);
	}

	.nm {
		.dFlex;
		align-items: center;

		@avatarSize: 40px;

		.avatar {
			.dInline;
			.circle(@avatarSize);
			.dFlex;
			.flexCntr;
			.flexAi;
			.bgr(@topBarSrchBgr);
			.mr(15px);

			img {
				.circle(@avatarSize);
				object-fit: cover;
				//.border(5px, #fff);
			}

			i {
				//.clr(darken(@topBarSrchBgr, 20%));
				.clr(#fff);
				.fs(22px);
			}
		}

		span {
			.fs(0.8rem);
			.clr(@txtLightClr);
			.ml(15px);
		}

		.lnk {
			.m(0 20px);

			i {
				.fs(16px);
				.clr(#ccc);
			}
		}
	}

	.controls {
		opacity: 0;
		transition: all @transitionsTime;

		a {
			.clr(@txtLightClr);
			.fs(0.8rem);
			.dInline;
			.ml(20px);

			&:hover {
				.clr(#000);
			}
		}
	}

	.c {
		p {
			.m(10px 0);
		}
	}

	//blockquote {
	//	.m(10px 0);
	//	.p(10px);
	//	.bgr(#f1f1f1);
	//	.border(darken(#f1f1f1, 10%));
	//	.baradius(5px);
	//}


}
.comment-item {
	&.pinned {
		.bl(@orangeClr, 5px);
	}
}
.comment-hdr {
	.w100;
	.dFlex;
	.flexWrap;
	.flexSb;
	.flexAi;
}

.comment-files {
	.win {
		border-top: 0;
	}
	.file-uploader-win {
		.mb(0);

		.-wrap {
			.p(0);
		}

		.form-item {
			.mb(0);
		}
	}

	.-open {
		span {
			.clr(@blueClr);
			.bb(@blueClr, 1px, dotted);
			.pointer;
		}
	}
}

.comment-add {
	.dFlex;

	.bgr(@topBarSrchBgr);
	//.p(@winPadLR);
	.p(10px @winPadLR);
	.baradius(5px);
	.winShadow();


	@avatarSize: 60px;

	.avatar {
		.circle(@avatarSize);
		.dFlex;
		.flexCntr;
		.flexAi;
		.bgr(#fff);

		img {
			.circle(@avatarSize);
			object-fit: cover;
			.border(5px, #fff);
		}

		i {
			.clr(darken(@topBarSrchBgr, 20%));
			.fs(26px);
		}
	}


	&.-placeholder {
		.p(10px @winPadLR);

		.like-input {
			.border(@borderColor);
			flex: 1;
			.bgr(#fff);
			.p(10px);
			.ml(@winPadLR);
			.baradius(5px);
			.clr(#ccc);
			.dFlex;
			.flexAi;
		}
	}

	.wrp {
		flex: 1;
		.ml(@winPadLR);
	}

	.btns {
		.btn {
			.mr(60px);
		}

		a {
			.clr(#ccc);

			&:hover {
				.clr(@txtClr);
			}
		}
	}
}
.comment-children {
	.pl(5vw);
}

.tasks-filter {
	.flexSb;
	> * {
		//flex: 1;
		.w100;
		.p(0 10px 0 0);
	}
}

.tasks-filter-title {
	.dFlex;
	.flexAi;

	span {
		.m(0 10px);
		.fs(.9rem);
	}
}
@media screen and (min-width: 1200px) {
	.tasks-filter {
		> * {
			.w(49%);
			.mb(10px);
		}

		> .col-status {
			.w100;
		}
	}
}
@media screen and (min-width: 1600px) {
	.tasks-filter {
		> * {
			.w(33%) !important;
		}
	}
}
@media @mobileSmall {
	.tlt-inf {
		.flexWrap;
		> div {
			.m(5px 0);
			.w(100%);
		}
	}
}
