@import "mixins";
@import "config";

.projects-list {
  .bgr(#fff);

  .col-act {
	.w(3%);
  }
  .col-name {
	.w(30%);
	a {
	  .clr(inherit);
	  &:hover {
		.tdUnd;
	  }
	}
  }
  .col-sites {
	flex: 1;
  }
  .col-owner {
	.w(15%)
  }

  .pl-site {
	.m(5px);
	.clr(@txtClr)
  }
  .pl-name-descr {
	.mt(1rem);
	.clr(@greyBgrHover);
	.fs(.8rem);
  }

  .unpublish {
	opacity: .5;
	&:hover {
	  opacity: 1;
	}
  }
}
.site-edit-access {
	h4 {

	}
}
.site-access-field {
  .col-name {
	.w(40%);
  }
  .col-val {
	//.w(50%);
	flex: 1;
	.pl(20px);
  }
  .col-del {
	.w(3%);
	.tRight;
	.pt(30px);

	i {
	  &:hover {
		.clr(@orangeClr);
		.pointer;
	  }
	}

  }
}