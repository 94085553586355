@import 'mixins.less';
@import 'config.less';

.users-list {
	.-info {
	  .mt(10px);
	  .pt(5px);
	  .bt(@borderClr, 1px, dotted);
	  .clr(@blueClr);
	  .fs(90%);
	  p {
		.p(0);
		.m(5px 0 0 0)
	  }
	}
  .-name {
	.im {
		.mr(10px);
	  //.circle(30px);
		//.shadow(0px, 0px, 2px, 0px, 0.5);
		//background-position: center center;
		//background-size: cover;
		.dFlex;
		.flexCntr;
		.flexAi;


		img {
			object-fit: cover;
			.circle(30px);
			//.shadow(0px, 0px, 2px, 0px, 0.5);
		}
		i {
			//.dInline;
			//.circle(30px);
			//.shadow(0px, 0px, 2px, 0px, 0.5);
			.fs(30px);
			.clr(#ccc);
		}
	}
	a {
	  .clr(@txtClr);
	  &:hover {
		.tdUnd;
	  }
	}
  }
}
.user-edit-avatar {
  h3 {
	.mb(10px);
  }
  .-av {
	.wMax(200px);
	.mr(20px);
	img {
	  .w100;
	}
  }
}
.online-users {
  .fixed;
  right: 0;
  top: @topBarHeight;
  //.z(@zTop);
  //.bgrRGBA(.8);
  .bgr(#fff);
  .w(70%);
  .hMax(80vh);
  overflow: auto;
  //.box(50%, 20vh);
  .border(#000);
  .dNone;

  &.show {
	.dBlock;
  }


  .-wrp {
	.box(100%);
	.relative;
  }
  .close {
	.absolute;
	.fs(1.2rem);
	top: 10px;
	right: 10px;
	&:hover {
	  .clr(@greenClr);
	  .pointer;
	}
  }

  .ou-ua {
	.fs(.7rem);
  }
  .ou-url {
	.fs(.7rem);
  }
}
