@import 'mixins.less';
@import 'config.less';
@import 'ghoulstrap.less';
@import 'forms.less';
@import 'scrollbar.less';
@import 'toastr.less';
@import 'users.less';

//@import url(https://fonts.googleapis.com/css?family=Roboto&amp;subset=cyrillic);

html {
	box-sizing: border-box;
	moz-box-sizing: border-box;
	.h(100%);
}

*, *:before, *:after {
	box-sizing: inherit;
}

//
//ul li {
//	list-style: none;
//}


body {
	font-family: 'Roboto', sans-serif;
	//font-family: Arial, Tahoma, sans-serif;
	.clr(@txtClr);
	.fs(@fontSize);
	.zeromp();
	//.bgr(@bodyBgr);
	background: @bodyBgr url(../img/bgr/email-pattern.png);

	//background: @bodyBgr url(../img/bgr/sea2.jpg);
	//background-size: cover;
	background-attachment: fixed;
	.h(100%);
	//.dFlex;
	@media print {
		.clr(#000);
		background: #fff;
	}
}


h1, h2, h3 {
	.zeromp;
}

h1 {
	.mb(40px);
}

a {
	.tdNone;

	&:hover {
		.tdNone;
	}

	@media print {
		.clr(#000);
		.tdUnd;
	}
}

.ml20 {
	.ml(20px);
}


textarea {
	resize: none;
	height: calc(10rem + 2px);
	.fs(inherit);
	.fFamily(inherit);
}

select {

	&.tbl-sorter-select {
		padding: 0;
	}

	&.hidden {
		border: none;
		-webkit-appearance: none;
		appearance: none;

		&::-ms-expand {
			display: none;
		}

		option {
			.clr(@txtClr);
		}
	}

	@media print {
		-moz-appearance: none;
		/* for Chrome */
		-webkit-appearance: none;

		&:-ms-expand {
			display: none;
		}
	}
}

hr {
	.bgr(@winLineClr);
	.box(100%, 1px);
	.m(20px auto);
	border: 0;
}

.hr {
	.bgr(@txtClr);
	.box(100%, 2px);
	.m(20px auto);
	border: 0;
}

.LoadingBar {
	.z(10000);
	.h(5px);
}

//////////////
.main-wrap {
	.box(100%);

}

main {
	.dInline;
	.w100;
	.ml(auto);
	.mr(auto);
}

.content {
	//.bgr(#000);
	.w100;
	.p(20px 20px 100px 20px);
	.ml(auto);
	.mr(auto);

	h1, .h1 {
		.fs(1.8rem);
	}
}

.content-header {
	.mb(40px);
}

.content-title {
	.dFlex;
	align-items: flex-end;


	> * {
		.m(0 10px 0 0);

	}

	> a {


		span {
			//.tdUnd;
			.clr(@greyBgrHover);
			//.tdNone;
			&:hover {
				.tdUnd;
			}
		}

		&:after {
			content: "/";
			.p(0 10px);
			.clr(@txtLightClr);
		}
	}

	h1 {
		.lh(90%);
	}

	@media print {
		a {
			.tdNone;

			&:first-child {
				.fs(1.8rem);
				.bold;
			}
		}
	}
}

.ms-top {
	.fixed;
	left: 0;
	top: 0;
	.z(@zTop);
	.h(@topBarHeight);
	.bb(@greyBgrLineClr);
	align-items: center;
	.pTwo(0, @menuPaddingLR);
	.bgr(@greyBgr);
	.w(@menuSideWidthClose);
	transition: all @transitionsTime;


	//.logo {
	//	.dNone;
	//}
	&.open {
		.w(@menuSideWidth);
	}

	&:not(.open) {
		.fs(10px);
	}

	@media print {
		.dNone;
	}
}

.logo {
	a {
		.clr(@orangeClr);
		.bold;
	}
}

.menu-bars {
	.fs(2rem);
	.clr(@menuLinkClr);
	transition: all @transitionsTime;

	&:hover {
		.pointer;
		.clr(@menuLinkClrHover);
	}

}
.mobi-user {
	.dNone;
}
.menu-side {
	.fixed;
	top: @topBarHeight;
	.z(20);
	.w(@menuSideWidthClose);
	.h100;
	.bgr(@greyBgr);
	.shadow(1px, 0px, 3px, 0px, 0.5);
	transition: width @transitionsTime;


	hr {
		.bb(@greyBgrLineClr);
		.h(0px);
		.m(10px 0);
	}

	.mCSB_container, .mCustomScrollBox {
		overflow: visible !important;
	}

	&:not(.open) {
		.menu-side-scroll {
			.ml(-8px);

			.menu {
				//.p(20px 24px);
			}
		}

		//.menu {
		//  .w(80px);
		//}
	}

	@media print {
		.dNone;
	}
}

.menu-side-scroll {
	overflow: visible !important;

	> div {
		&:first-child {
			overflow: visible !important;
		}
	}

}

.menu {
	.p(0 @menuPaddingLR);

	ul {
		> li {
			.m(3px 0);
			.relative;

			> a {
				.dFlex;
				align-items: center;
				.tdNone;
				.clr(@menuLinkClr);
				.p(10px 0px);
				.baradius(4px);
				transition: all @transitionsTime;
				outline: none;

				i {
					.fs(0.95rem);
					.w(16px);
					.tac;
				}

				&.active {
					.bgr(@blueClr);
					.clr(@menuLinkClrHover);
				}

			}

			&:not(.as-title), &:not(.as-title) {
				&:hover {
					> a {
						.bgr(@greyBgrHover);
						.clr(@menuLinkClrHover);
					}

					> .menu-sub {
						.dBlock;
					}
				}
			}

			&.active {
				> a {
					.bgr(@blueClr);
					.clr(@menuLinkClrHover);
				}

				//.menu-sub {
				//	.dBlock;
				//}
			}


		}
	}
}

.menu-sub {

	//.bt(@greyBgr);
	.br(@greyBgr);
	.bb(@greyBgr);
	//transition: all @transitionsTime;
	a {
		.fs(0.8rem);
		.clr(#fff) !important;

	}

	&.fixed {
		.dBlock;
		.pl(20px);
	}

	&.pup {
		.dNone;
		.absolute;
		left: 98%;
		top: 0;
		.z(30);
		//.bgr(darken(@greyBgr, 2%));
		.bgr(@greyBgrHover);
		.noWrap;
		.baradius(4px);
		//.shadow(1px, 1px, 2px, )
		//box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, .5);

		li {
			.dBlock;
			.p(0 5px);

			&:hover {
				> a {
					.bgr(@greyBgr) !important;
				}
			}
		}
	}
}


.top-bar {
	.fixed;
	top: 0;
	right: 0;
	.z(10);
	.w(calc(100% - @menuSideWidth));
	.hMin(@topBarHeight);
	.bgr(#fff);
	.shadow(0px, 1px, 3px, 0px, 0.5);
	//align-items: center;
	.flexSb;

	.p(0px @menuPaddingLR);
	transition: width @transitionsTime;

	&.full {
		.w100;
	}
}

.tb-left {
}

.tb-menu {
	li {
		.mr(10px);

		a {
			.clr(@txtClr);
			.fs(0.8rem);

			&:hover {
				.tdUnd;
			}
		}

		i.fa {
			.mr(5px);
		}

		&:not(:last-child) {
			.pr(10px);
			.br(@borderColor);
			.mr(10px);
		}
	}

	.show-hover {
		//visibility: hidden;
		position: fixed;
		top: -1000px;
	}

	&:hover {
		.show-hover {
			position: static;
		}
	}
}

.tb-srch {

	.relative;
	.bgr(@topBarSrchBgr);
	.baradius(5px);

	input {
		.w(80%);
		border: 0;
		.bgr(transparent);
	}

	button {
		border: 0;
		.zeromp;
		.fs(1.5rem);
		.bgr(transparent);
		.absolute;
		.absoluteTopCenter;
		right: 10px;
		.clr(@greyBgr);

		&:hover {
			//.clr(#000);
			i {
				.clr(@blueClr);
			}
		}

		&:active {
			//.m(0px 0 0 3px);
			//.p(0 0 0 0 2px)
			.mt(0.5px);
			right: 9.5px;

			i {
				.clr(@blueClr);
			}
		}
	}

	> i {
		.fs(0.8rem);
		.absolute;
		.absoluteTopCenter;
		right: 50px;
		.clr(darken(@topBarSrchBgr, 20%));
		//&:hover {
		//  .clr(@greyBgr);
		//  .pointer;
		//}
	}

	&.hidden {
		.dNone;
	}
}

.tb-right {

}

.tb-notifs {
	> a {
		.relative;
		.mTwo(0, 15px);

		i {
			.fs(1.3rem);
			.clr(@txtClr);

		}

		.count {
			.absolute;
			right: -10px;
			top: -10px;
			.bgr(@redClr);
			.clr(#fff);
			.fs(0.7rem);
			.p(1px 2px);
			.baradius(2px);
		}

		&:hover {
			i {
				.clr(@blueClr);
			}
		}
	}
}

.topbar-notify {
	.relative;

	.ico {
		.relative;

		i {
			.clr(@greyBgr);
			.fs(24px);
		}

		span {
			.absolute;
			top: 0;
			right: 0;
			.circle(14px);
			.flexInCntr;
			.fs(10px);
			.bgrRGBA(0.8);
			.clr(#fff);
		}
	}


	//&:hover {
	//	.topbar-notify-list {
	//		.dBlock;
	//	}
	//}
}

.topbar-notify-list {
	.absolute;
	right: 0;
	top: 100%;
	.bgr(#fff);
	.w(400px);

	.winShadow();
	.hMax(80vh);
	overflow-y: auto;
	.dNone;

	&.active {
		.dBlock;
	}


	> li {
		.p(10px 0);
		.fs(13px);
		.dFlex;
		.flexAi;

		.t {
			flex: 1;
			.p(0 5px 0 15px);
		}

		.d {
			.w(25%);
			.tac;
			.clr(darken(#fff, 50%));
			//.bgr();
		}

		.dl {
			.w(10%);
			.tac;
			.clr(darken(#fff, 8%));
			//.bgr();
			i {
				.pointer;
			}
		}

		&:nth-child(even) {
			.bgr(darken(#fff, 5%));
		}

		&:hover {
			.bgr(darken(#fff, 10%));

			.dl {
				.clr(darken(#fff, 50%))
			}
		}
		&.linked {
			.pointer;
			//.t {
			//	.tdUnd;
			//}
		}
	}
}

.tb-user {
	&:before {
		content: "";
		.box(1px, @topBarHeight * 0.5);
		.bgr(@topBarSrchBgr);
		.m(0 @menuPaddingLR);
	}

	.im {
		.circle(@topBarHeight * 0.8);

		.mr(10px);
		//.shadow(0px, 0px, 2px, 0px, 0.5);
		//.border(@greyBgr);
		background-position: center center;
		background-size: cover;

		&.online {
			.border(@greenClr);
		}
	}

	.n {
		//.fs(0.95rem);
		.wMax(140px);
		.overflow;

		span {
			.clr(#ccc);
			.fs(0.7rem);
		}
	}

	.actions {
		.pl(20px);
	}
}

//всплывашка
.popup-win {
	.fixed;
	.absoluteCenter;
	.z(10000);
	.bgrRGBA(.5);
	.box(100vw, 100vh);

	.dFlex;
	align-items: center;
	.flexSa;

	.win {
		.hMax(90vh);

		&.wide {
			.w(70vw);

			@media (max-width: 1200px) {
				.w(80vw);
			}
			@media (max-width: 800px) {
				.w(96vw);
			}
		}

		&.half {
			.w(50vw);

			@media (max-width: 1200px) {
				.w(80vw);
			}
			@media (max-width: 800px) {
				.w(96vw);
			}
		}
	}


}

//////////////////////////
//////  окно
//////////////////////////
@keyframes animShow {
	0% {
		//transform: scale(.8);
		opacity: .3;
	}
	100% {
		//transform: scale(1);
		opacity: 1;
	}
}

@keyframes animScale {
	0% {
		transform: scale(.8);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes winHide {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(.7);
	}
}

.win {
	.relative;
	.dFlex;
	flex-direction: column;
	.flexSb;
	//border: 0 solid @winLineClr;
	//box-shadow: 0 0 1px @winLineClr, 0 1px 3px rgba(0, 0, 0, .2);
	.winShadow();
	.mb(@winMarginBtm);
	.bgr(#fff);
	.baradius(5px);


	//верхняя часть
	> .h {
		.p(10px @winPadLR);
		.clr(#000);
		//.fs(1.2rem);
		.fs(20px);
		.bb(@winLineClr);
		.bradius(5px, 5px, 0, 0);
		.relative;
		.dFlex;
		.flexSb;

		&.-wc {
			//.pr(@winPadLR + 40px);
		}

		> .controls {
			//.fs(0.8rem);
		}

		//кнопки иконки - закрыть свернуть
		.b {
			.dInline;
			//.fs(24px) !important;
			.ml(15px);

			&:before {
				.clr(@txtLightClr);
				transition: all @transitionsTime;
			}

			&:hover {
				&:before {
					.clr(#000);
					.pointer;
				}
			}

			//&.div {
			//	.br(#fff);
			//	.box(1px, 16px);
			//}
			&.space {
				.w(15px);
			}

		}

		.minimize {
			.clr(@txtLightClr);

			&:before {
				content: "\f2d1";
			}

			&.active {
				&:before {
					content: "\f2d0";
				}
			}

			&:hover {
				.pointer;
				.clr(#000);
			}
		}
	}

	//содержимое
	> .c {
		overflow-y: auto;
		.baradius(5px);

		.-wrap {
			.p(@winPadLR);
		}

		&.pad {
			.p(@winPadLR);
		}

		&.hide {
			.dNone;
		}

		&.-no-overflow {
			overflow: visible;
		}
	}

	//нижняя часть
	> .f {
		.p(10px @winPadLR);
		//.bgr(@winLineClr);
		.bgr(@topBarSrchBgr);
		.bradius(0, 0, 5px, 5px);
	}

	&.-green {
		.bgr(@greenClr);

		> .h {
			//.bb(lighten(@greenClr, 10%));
			border-bottom: none;
			.clr(#fff);
		}

		> .f {
			.bgr(#228E3B);

			a {
				.clr(#fff);
			}
		}
	}

	&.note {
		.bl(@redClr, 5px);
	}

	&.-grey {
		.bgr(@topBarSrchBgr);
	}

	&.-clean {
		box-shadow: none;
		.bgr(transparent);
	}

	.sub-win {
		.bgr(@topBarSrchBgr);
		.baradius(5px);
		.p(@winPadLR);
	}

	&.minimized {
		.overflow;

		> .h {
			border-bottom: 0;

		}
	}

	&.only-footer {
		> .f {
			.baradius(5px);
		}
	}

	&.hdr-blue {
		> .h {
			.bgr(@blueClr);
			.clr(#fff);

			.fa {
				&:before {
					.clr(#fff) !important;
				}
			}
		}
	}

	&.hdr-green {
		> .h {
			.bgr(@greenClr);
			.clr(#fff);

			.fa {
				&:before {
					.clr(#fff) !important;
				}
			}
		}
	}

	&.hdr-grey {
		> .h {
			.bgr(@topBarSrchBgr);
			//.clr(#fff);
			//.fa {
			//	&:before {
			//		.clr(#fff) !important;
			//	}
			//}
		}
	}
}

.win-wide {
	.dFlex;
	.box(100%);
	.flexCntr;
	align-items: center;
}

.win-ftr-btns {
	.btn {
		&:not(:first-child) {
			.ml(20px);
		}
	}
}

.btn {
	//.dInline;
	.dFlexi;
	.p(10px 20px);
	.clr(#fff);
	.bgr(@blueClr);
	.baradius(5px);
	transition: all @transitionsTime;
	border: 0;
	.fs(1rem);
	.pointer;
	.relative;

	&:hover, &:active {
		.bgr(lighten(@blueClr, 10%));
	}

	&.-green {
		.bgr(@greenClr);

		&:hover, &:active {
			.bgr(lighten(@greenClr, 10%));
		}
	}

	&.-grey {
		.bgr(@greyBgrHover);

		&:hover, &:active {
			.bgr(@greyBgr);
		}
	}

	&.-lgrey {
		.bgr(lighten(@greyBgrHover, 50%));

		&:hover, &:active {
			.bgr(lighten(@greyBgrHover, 30%));
		}
	}

	&.-red {
		.bgr(@redClr);

		&:hover, &:active {
			.bgr(lighten(@redClr, 10%));
		}
	}

	&.-small {
		.p(5px 10px);
		.fs(.8rem);
	}

	&.disabled {
		opacity: .1;
	}

	.-in {
		.pointer;
		.dFlex;
		align-items: center;

		i {
			.mr(10px);
		}

		&.emptyTitle {
			i {
				.mr(0);
			}
		}
	}

	.tip {
		//.dNone;
		.absolute;
		.p(5px);
		.bgr(@greyBgrDark);
		.clr(#fff);
		top: 101%;
		.absoluteLeftCenter;
		.fs(.8rem);
		.baradius(3px);
		transition: all @transitionsTime;
		.wMin(100%);
		.noWrap;
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}

	&.-icon-only {
		.-in {
			i {
				.mr(0);
			}
		}
	}
}

.tbl {
	border-collapse: collapse;
	.bl(@borderColor);
	.bt(@borderColor);
	.br(@borderColor);

	.w100;


	tr, .tr {
		.bgr(#fff);
	}

	//для DIVов
	.tr {
		.dFlex;
		.flexWrap;
		.zero;
	}

	td, .td {
		.bb(@borderColor);
		.p(10px);
		hyphens: auto;


		&.-bgr {
			.bgr(@topBarSrchBgr);
		}

		&.editable {
			&:after {
				font-family: "FontAwesome";
				content: "\f14b";
				.absolute;
				top: 1px;
				right: 1px;
				.clr(@blueClr);
				.fs(.8rem);
				opacity: .0;
			}

			&:hover {
				//cursor: text;
				&:after {
					//.dBlock;
					opacity: 1 !important;
				}
			}
		}

		&.sortable {
			.pointer;
		}

		&.editText {
			.p(0);
			//.dFlex;
			.editor {
				.p(10px);
				white-space: pre-wrap;
				outline: none;
				.box(100%);
				//cursor: url(../img/pencil.png), text;
				//.bgr();
			}
		}
	}

	.td {
		.relative;
	}

	thead, .thead {
		.bold;
		//.noWrap;
		select {
			.bold;

			option {
				.fw(400);
			}
		}
	}

	.thead {
		> .tr {
			align-items: stretch;
			//align-content: center;
			.td {
				.dFlex;
				align-items: center;
			}
		}
	}

	&.hdr-cntr {
		thead {
			td {
				.tac;
			}
		}

		.thead {
			.td {
				.tac;
				.flexSa;
			}
		}

		.td-title {
			.tac;
		}
	}

	tfoot {
		.bgr(@topBarSrchBgr);
	}

	tbody {
		td {
			.relative;
			background-clip: padding-box;
		}
	}


	&.dark {
		thead, .thead {
			td, .td {
				.bgr(@topBarSrchBgr);
				.bb(@borderColor);

				&:last-child {
					.br(@borderColor);
				}
			}

		}
	}

	&.-blue {
		thead, .thead {
			td, .td {
				.bgr(@blueClr);
				.clr(#fff);
				.bb(@borderColor);

				&:last-child {
					.br(@borderColor);
				}
			}

		}
	}

	&.-bordered {

		//&.dark {
		//  thead, .thead {
		//	td, .td {
		//	  .br(@borderColor);
		//	}
		//  }
		//}
		//tbody, .tbody {
		//  td, .td {
		//	.br(@borderColor);
		//  }
		//}
		.td {
			&:not(:last-child) {
				.br(@borderColor);
			}
		}
	}

	&.-zebra {
		tbody, .tbody {
			tr, .tr {
				&:nth-child(even) {
					.bgr(#fafafa);
				}

				&:hover {
					td, .td {
						.bgr(#f6f6f6);
					}
				}
			}
		}
	}

	&.-light {
		thead, .thead {
			td, .td {
				.bgr(@borderColor);
				.clr(@greyBgrHover);
			}
		}

		td {
			&.st {
				.bgr(lighten(yellow, 40%));
			}

			&.su {
				.bgr(lighten(red, 35%));
			}
		}
	}


	@media print {


		.bl(@borderClrPrint);
		.bt(@borderClrPrint);
		.br(@borderClrPrint);

		.tr {
			.bgr(#fff) !important;
			.bt(@borderClrPrint, 1px);
		}

		td, .td {
			.bb(@borderClrPrint, 1px);
			position: static !important;

			&.editable {
				&:after {
					.dNone;
				}
			}
		}

		.td-title {
			.dNone() !important;
		}

		&.dark {
			.thead {
				.td {
					.br(@borderClrPrint);
					.bb(@borderClrPrint);
				}

			}
		}

		&.-blue {
			.thead {
				.td {
					.bgr(@topBarSrchBgr);
					.clr(@txtClr);
				}

			}
		}

		&.-bordered {
			.td {
				.br(@borderClrPrint) !important;
			}
		}

		.tbody {
			.tr {
				.bt(@borderClrPrint);
			}
		}

	}
}

.tbl-sort {
	.dInline;
	.fs(1.1rem);
	.ml(10px);
	.clr(#ccc);

	&:hover {
		.clr(@blueClr);
	}

	&.active {
		.clr(#000);
	}
}

///кнопка с выбором действий
.actions {
	.relative;

	i {
		.fs(1.2rem);
		//.clr(@txtLightClr);
		.clr(lighten(@txtLightClr, 20%));
	}

	> div {
		.dNone;
		.absolute;
		top: 20px;
		.z(10);
		.bgr(#fff);
		.border(@winLineClr);
		.shadow(0px, 0px, 2px, 0px, 0.4);

		> * {
			.dBlock;
			.p(10px 20px);
			.dFlex;
			.clr(@txtClr);
			align-items: center;

			i {
				.mr(10px);
				.fs(0.95rem);
			}

			&:hover {
				.bgr(lighten(@borderColor, 5%));
				.clr(#000);

				i {
					.clr(#000);
				}
			}
		}

		&:hover {
			.dBlock;
		}
	}

	&:hover {
		> i {
			.clr(@txtLightClr);
		}
	}

	&.open {
		//> i {
		//	.clr(#000);
		//}

		> div {
			.dBlock;
		}
	}

	&.-left {
		div {
			left: 0;
		}
	}

	&.-right {
		div {
			right: 0;
		}
	}

}


.login-win {
	.wMin(320px);

	a {
		.tdUnd;

		&:hover {

		}
	}
}

.panel-top {
	.btn {
		.ml(5px);
		.mr(5px);
	}
}

.scroll-top-btn {
	.fixed;
	bottom: 1vh;
	right: 1vw;
	.z(100000);
	.fs(2rem);
	.clr(#ccc);
	.dNone;
	transition: all @transitionsTime;

	&.active {
		.dBlock;

	}

	&:hover {
		.clr(@txtClr);
		.pointer;
	}
}

fieldset {
	.border(@borderColor);
	.baradius(10px);

	legend {
		.clr(@greenClr);
	}
}

.code {
	.border(#000);
}

.tip {
	.relative;
	.dInline;

	&:hover {
		.tip-popup {
			//.dBlock;
			//visibility: visible;
		}
	}

	.show {
		.tip-popup {
			.dBlock;
			opacity: 1;
		}
	}
}

.tip-popup {
	.absolute;
	.p(5px);
	.bgr(@greyBgrDark);
	.clr(#fff);
	top: 101%;
	.absoluteLeftCenter;
	.fs(.8rem);
	.baradius(3px);
	.wMin(100%);
	//.w(250px);
	.noWrap;
	.dNone;
	opacity: 0;
	.z(1);
	transition: all @transitionsTime;
}

.page-404 {
	.h(80vh);
	.dFlex;
	.flexAi;
	.flexCntr;
	.fs(6rem);
	.tac;
}

//@import 'docs';
//@import 'sklad_settings';
@import 'logs';
@import 'projects';
@import 'tasks';
@import 'media';
