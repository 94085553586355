@import 'mixins.less';
@import 'config.less';

@-webkit-keyframes "bounceIn" {
  from,20%,40%,60%,80%,to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
	opacity: 0;
	-webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
	transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
	-webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
	transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
	-webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
	transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
	opacity: 1;
	-webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
	transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
	-webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
	transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
	opacity: 1;
	-webkit-transform: perspective(1px) scale3d(1, 1, 1);
	transform: perspective(1px) scale3d(1, 1, 1);
  }
}
@keyframes "bounceIn" {
  from,20%,40%,60%,80%,to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
	opacity: 0;
	-webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
	transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
	-webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
	transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
	-webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
	transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
	opacity: 1;
	-webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
	transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
	-webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
	transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
	opacity: 1;
	-webkit-transform: perspective(1px) scale3d(1, 1, 1);
	transform: perspective(1px) scale3d(1, 1, 1);
  }
}
@-webkit-keyframes "bounceOut" {
  20% {
	-webkit-transform: scale3d(0.9, 0.9, 0.9);
	transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,55% {
	opacity: 1;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
	opacity: 0;
	-webkit-transform: scale3d(0.3, 0.3, 0.3);
	transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes "bounceOut" {
  20% {
	-webkit-transform: scale3d(0.9, 0.9, 0.9);
	transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,55% {
	opacity: 1;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
	opacity: 0;
	-webkit-transform: scale3d(0.3, 0.3, 0.3);
	transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-webkit-keyframes "fadeIn" {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}
@keyframes "fadeIn" {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}
@-webkit-keyframes "fadeOut" {
  from {
	opacity: 1;
  }
  to {
	opacity: 0;
  }
}
@keyframes "fadeOut" {
  from {
	opacity: 1;
  }
  to {
	opacity: 0;
  }
}
@-webkit-keyframes "bounceInDown" {
  from,60%,75%,90%,to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
	opacity: 0;
	-webkit-transform: translate3d(0, -3000px, 0);
	transform: translate3d(0, -3000px, 0);
  }
  60% {
	opacity: 1;
	-webkit-transform: translate3d(0, 25px, 0);
	transform: translate3d(0, 25px, 0);
  }
  75% {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
  }
  90% {
	-webkit-transform: translate3d(0, 5px, 0);
	transform: translate3d(0, 5px, 0);
  }
  to {
	-webkit-transform: none;
	transform: none;
  }
}
@keyframes "bounceInDown" {
  from,60%,75%,90%,to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
	opacity: 0;
	-webkit-transform: translate3d(0, -3000px, 0);
	transform: translate3d(0, -3000px, 0);
  }
  60% {
	opacity: 1;
	-webkit-transform: translate3d(0, 25px, 0);
	transform: translate3d(0, 25px, 0);
  }
  75% {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
  }
  90% {
	-webkit-transform: translate3d(0, 5px, 0);
	transform: translate3d(0, 5px, 0);
  }
  to {
	-webkit-transform: none;
	transform: none;
  }
}
@-webkit-keyframes "bounceOutUp" {
  20% {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
  }
  40%,45% {
	opacity: 1;
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, -2000px, 0);
	transform: translate3d(0, -2000px, 0);
  }
}
@keyframes "bounceOutUp" {
  20% {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
  }
  40%,45% {
	opacity: 1;
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, -2000px, 0);
	transform: translate3d(0, -2000px, 0);
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.bounceIn {
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.animated.bounceOut {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}
.animated.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
}
.animated.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}
.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  .shadow {
	width: 100%;
	height: 100%;
	background-color: rgba(50,58,68,0.8);
  }
  .rrt-confirm {
	width: 320px;
	background-color: white;
	position: absolute;
	z-index: 9;
	top: 20%;
	left: 50%;
	margin-left: -160px;
	box-shadow: 3px 3px 20px #333;
	border-radius: 4px;
	overflow: hidden;
	.rrt-message {
	  width: 100%;
	  padding: 5%;
	  min-height: 50px;
	  font-size: 1em;
	  background-color: white;
	  text-align: center;
	  font-family: 'open-sanscondensed-light', sans-serif;
	  clear: both;
	}
	.rrt-buttons-holder {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  .rrt-button {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		height: 50px;
		text-transform: capitalize;
		border: none;
		background-color: transparent;
		padding: 0;
		margin: 0;
		float: left;
		border-top: 1px solid #f0f0f0;
		font-size: 14px;
		overflow: hidden;
		cursor: pointer;
		&:hover {
		  background-color: #f5f5f5;
		}
		&:focus {
		  outline: none;
		}
	  }
	  .rrt-button.rrt-ok-btn {
		&:active {
		  background-color: #60bb71;
		  color: white;
		}
	  }
	  .rrt-button.rrt-cancel-btn {
		&:active {
		  background-color: #db6a64;
		  color: white;
		}
	  }
	}
  }
}
body.toastr-confirm-active {
  overflow: hidden;
}
@toastWidth: 400px;
.redux-toastr {
  * {
	box-sizing: border-box;
	&:before {
	  box-sizing: border-box;
	}
	&:after {
	  box-sizing: border-box;
	}
  }
  .top-left {
	.w(@toastWidth);
	position: fixed;
	z-index: 99999999;
	padding: 0 10px;
	top: 0;
	left: 0;
  }
  .top-right {
	.w(@toastWidth);
	position: fixed;
	z-index: 99999999;
	padding: 0 10px;
	top: 55px;
	right: 0;
  }
  .top-center {
	//.w(@toastWidth);
	//position: fixed;
	//z-index: 99999999;
	//padding: 0 10px;
	//top: 0;
	//left: 50%;
	//margin-left: -175px;
	.wMin(@toastWidth);
	position: fixed;
	z-index: 99999999;
	padding: 0 10px;
	.absoluteCenter;
  }
  .bottom-left {
	.w(@toastWidth);
	position: fixed;
	z-index: 99999999;
	padding: 0 10px;
	bottom: 0;
	left: 0;
  }
  .bottom-right {
	.w(@toastWidth);
	position: fixed;
	z-index: 99999999;
	padding: 0 10px;
	right: 0;
	bottom: 0;
  }
  .bottom-center {
	.w(@toastWidth);
	position: fixed;
	z-index: 99999999;
	padding: 0 10px;
	bottom: 0;
	left: 50%;
	margin-left: -175px;
  }

  .toastr {
	background-color: #fcfcfc;
	width: 100%;
	min-height: 70px;
	overflow: hidden;
	margin: 10px 0;
	border-radius: 5px;
	position: relative;
	z-index: 2;
	color: #333;
	opacity: .94;
	//box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
	&:hover {
	  &:not(.rrt-message) {
		//box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
		opacity: 1;
	  }
	}
	.toastr-status {
	  width: 100%;
	  height: 5px;
	}
	.toastr-status.success {
	  background-color: #60bb71;
	}
	.toastr-status.warning {
	  background-color: #f7a336;
	}
	.toastr-status.info {
	  background-color: #58abc3;
	}
	.toastr-status.error {
	  background-color: #db6a64;
	}
	.rrt-left-container {
	  float: left;
	  text-align: center;
	  overflow: hidden;
	  width: 80px;
	  top: 0;
	  left: 0;
	  position: absolute;
	  bottom: 0;
	  .rrt-holder {
		width: 70px;
		height: 70px;
		position: absolute;
		top: 50%;
		margin-top: -35px;
		left: 5px;
		line-height: 60px;
	  }
	  .toastr-icon {
		fill: white;
		vertical-align: middle;
		margin-top: 5px;
	  }
	}
	.rrt-right-container {
	  float: left;
	  text-align: center;
	  overflow: hidden;
	  width: 10%;
	}
	.rrt-middle-container {
	  width: 65%;
	  margin-left: 80px;
	  position: relative;
	  float: left;
	  //font-family: Arial, Helvetica, sans-serif, sans-serif;
	  font-size: 1em;
	  text-align: left;
	  padding: 10px 5px;
	  .rrt-title {
		font-size: 1.1em;
		font-weight: bold;
		margin-bottom: 5px;
	  }
	}
	.close-toastr {
	  width: 10%;
	  height: 100%;
	  position: absolute;
	  top: 0;
	  right: 0;
	  background-color: transparent;
	  font-size: 22px;
	  border: none;
	  outline: none;
	  opacity: 0.5;
	  cursor: pointer;
	  font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
	  &:hover {
		opacity: 1;
	  }
	  &:focus {
		outline: none;
	  }
	}
	.rrt-progress-container {
	  height: 5px;
	  margin: 0 -20px -20px -60px;
	  position: absolute;
	  bottom: 20px;
	  width: 100%;
	  .rrt-progressbar {
		border-radius: 0 0 0 4px;
		height: 100%;
	  }
	}
  }
  .toastr.rrt-info {
	color: white;
	background-color: @blueClr;//#58abc3;
	.rrt-progressbar {
	  background-color: #378298;
	}
  }
  .toastr.rrt-success {
	color: white;
	background-color: @greenClr;//#60bb71;
	.rrt-progressbar {
	  background-color: #3e914d;
	}
  }
  .toastr.rrt-warning {
	color: white;
	background-color: @orangeClr;//#f7a336;
	.rrt-progressbar {
	  background-color: #d87e09;
	}
  }
  .toastr.rrt-error {
	color: white;
	background-color: @redClr;//#db6a64;
	.rrt-progressbar {
	  background-color: #c5352e;
	}
  }
  .toastr.rrt-light {
	.rrt-progressbar {
	  background-color: #ccc;
	}
	.toastr-icon {
	  fill: #333 !important;
	}
  }
  .toastr.rrt-message {
	opacity: 1;
	border: 1px solid #dbdbdb;
	.rrt-title {
	  width: 90%;
	  height: 50px;
	  text-align: center;
	  overflow: hidden;
	  font-size: 1.2em;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  white-space: nowrap;
	  line-height: 50px;
	  padding: 0 20px;
	}
	.rrt-text {
	  width: 100%;
	  max-height: 400px;
	  overflow: hidden;
	  overflow-y: auto;
	  border-top: 1px solid #f1f1f1;
	  border-bottom: 1px solid #f1f1f1;
	  background-color: white;
	  padding: 15px;
	  font-size: 1.1em;
	  margin-bottom: 20px;
	  img {
		display: block;
		margin: 10px auto;
		max-width: 100%;
	  }
	}
	.close-toastr {
	  height: 50px;
	}
  }
  .toastr-attention {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.4);
	z-index: 1;
  }
}
@media (max-width: 320px) {
  .redux-toastr {
	.top-left {
	  width: 320px;
	}
	.top-right {
	  width: 320px;
	}
	.top-center {
	  width: 320px;
	  margin-left: -160px;
	}
	.bottom-left {
	  width: 320px;
	}
	.bottom-right {
	  width: 320px;
	}
	.bottom-center, .center-center {
	  width: 320px;
	  margin-left: -160px;
	}
  }
}
